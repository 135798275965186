<div *ngIf="modeLocal || userDataService.dragOver" class="drop-zone-wrapper" [ngClass]="{'isFileOver': isFileOver, 'forced': forceShowDragZone}">
    <ngx-file-drop class="full-size-drop-zone"  (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
    </ngx-file-drop>
</div>
<!-- <div  class="drop-label">Drop files anywhere on the screen to upload!</div> -->
<div (click)="onDropLabelClick($event)" @inOutAnimation *ngIf="isFileOver || forceShowDragZone || forceShowDragZone && isFileOver " class="drop-label" [ngClass]="{'additionalbottommargin' : modeLocal, 'forced' : forceShowDragZone, 'isFileOver' : isFileOver}">
    <div class="drop-wrapper">
        <span>Drop models to upload</span>
        <ng-container>
          <div class="input-file-wrap">
            <label class="custom-file-label" for="folderInput">
              Or select folder for upload
            </label>
            <input id="folderInput" type="file" webkitdirectory multiple (change)="onFolderSelected($event)" class="input-hidden">
          </div>
        </ng-container>
    </div>
    <div class="forced-wrapper"></div>
</div>

