import { ModelCardRendered, ModelCardRenderedSelection, JsonDtoICard, IReviewDetails, ICardExtended } from './card';
import { ICollaborator } from './collaboration';



export const DEFAULT_USER_COLLECTION_ID = '_defaultUserCollection';
export interface ICardCollection {
  collectionId: string;

  collectionTitle: string;
  collectionContentMobile?: string;
  collectionPosterMobile?: string;
  collectionPosterWeb?: string;
  collectionContentWeb?: string;
  collectionAuthor?: string;
  collectionDescription?: string;

  collectionCards: ModelCardRenderedSelection[];

  purchaseDetails?: {
    allCardsQuantity: number;
  };

  showFullscreenBackgroundPreview?: boolean;
  accessType?: 'Private' | 'Collaborative';
  preloadSettings?: {
    enablePreload: boolean;
    numberOfCardsToPreload: number;
  };
  isSelected?: boolean;
  collaborators?: ICollaborator[];

  created: string;
  updated: string;
}

export interface JsonDtoICollection {
  author: string;
  description: string;
  id: string;
  previewLink: string;
  title: string;
  posterLink?: string;
  collectionCards: JsonDtoICard[];
}

export interface DtoICollection {
  collection_id: string;
  collection_poster_link_app: string;
  collection_poster_link_web: string;
  collection_preview_link_app: string;
  collection_preview_link_web: string;
  type: string;
  title: string;
  status: string;
  description: string;
  author_id: string;
  user_id: string;
  owner_id?: string; // TEMPORARY DELETE AFTER BACKEND FIX
  collection_models: DtoICard[];
  created: string;
  updated: string;
}

// preview_link_app
// preview_link_web
// poster_link_app
// poster_link_web

export interface DtoICard {
  model_id: string;
  // or other;
  owner_id?: string;
  shared_id?: string;
  original_id?: string;
  //


  public_model_id: string;
  card_category: string;
  card_subcategory: string;

  preview_link_app: string;
  poster_link_app: string;
  preview_link_web: string;
  poster_link_web: string;

  previewType: string;

  download_link: string;
  type: string;
  title: string;
  description: string;
  user_id: string;
  price: number;
  review_status: string;
  status?: string;
  review_details?: IReviewDetails[];
  last_update: string;
  creation_date?: string;

}

export interface LikesResponseDto {
  user_id: string;
  collections_likes?: [DtoICollection];
  models_likes?: [DtoICard];
}

export interface ILikedUserData {
  user_id: string;
  liked_collections: ICardCollection[];
  liked_models: ICardExtended[];
}

// {
//   "model_id": "default_model_id_1",
//   "user_id": "3DWay_default_user",
//   "preview_link": "https://storage-dev.3dway.io/preview/MODELFEED_HLS_10_1856_NOV2022_2250/Alpine_A5220000-0900/Alpine_A5220000-0900_1852_2250k.mp4",
//   "poster_link": "https://storage-dev.3dway.io/preview/MODELFEED_HLS_10_1856_NOV2022_2250/Alpine_A5220000-0900/Alpine_A5220000-0900_poster.webp",
//   "cardCategory": "",
//   "cardSubcategory": "",
//   "title": "default_title",
//   "price": 100,
//   "download_link": "dway-web-dev.appspot.com/uploaded_modeles/p4g8PrJJ1UbedQaY17YPYAQTZYH2/table1678661803965.usdz"
// }

export interface ICardCollectionMockSource extends  ICardCollection{
  cardImages: string[];
}
export interface ICollectionEditOptions {
  mode?: 'default' | 'edit' | 'active-edit' | 'purchased' | 'liked';
  isShortView?: boolean;
  isDescriptionEditing?: boolean;
  isSelected?: boolean;
}
