<app-navbar [@navbarmodelFeedAnimation]="navbarAnimationState" (@navbarmodelFeedAnimation.done)="onNavbarAnimationDone($event)"  #navbarComponent [footerBarMode]="currentFooterMode" [additionalData]="additionaNavData" [disableMainActionBtn]="isNoPurchasePossible(collectionDetailsSource)" (footermodeChange)="onFootermodeChanged($event)" (navbarButtonClick)="onNavbarBtnClicked($event)"  ></app-navbar>
<div [@cardFeedAnimation]="currentAnimationState"  class="mobile-shared-content-page" ngClass.lt-sm="mobile-shared-content-page-lt-sm" ngClass.lt-md="mobile-shared-content-page-lt-md" ngClass.gt-sm="mobile-shared-content-page-gt-sm" ngClass.gt-lg="mobile-shared-content-page-gt-lg">

    <div class="nodata-wrapper" *ngIf="isNoDataRecieved">
      <h1 class="nodata-title">Something went wrong :(</h1>
      <h3 class="nodata-desc">{{errorData}}</h3>
    </div>

    <app-models-card #collectionCardsFeed [feedIsVisible]="!isModelFeedVisible" [cardsRaw]="cards"  (cardClicked)="onCollectionClicked()" (cardPressed)="onModelCardPressed()" (cardVisibilityChanged)="onCardVisibilityChanged($event)" (actionBtnClicked)="onCardCollectionControlBtnClicked($event)" ></app-models-card>
    <app-collection-details #collectionDetailsComponent  *ngIf="isCollectionDetailsShown" [@feedCollectionDetailsAnimation]="isCollectionDetailsShown" (@feedCollectionDetailsAnimation.start)="onCollectionDetailsAnimationStart($event)" [@.disabled]="skipCollectionDetailsAnimation" (@feedCollectionDetailsAnimation.done)="onCollectionDetailsAnimationDone($event)" (collectionDetailsThumbClick)="onCollectionDetailsThumbClick($event)" (controlButtonClick)="onCollectionDetailsControlButtonClicked($event)"    [sourceCollection]="collectionDetailsSource" ></app-collection-details>
    <div [@homeOverscrollAnimation]="isModelFeedVisible" class="mobile-shared-content-overscroll-background" *ngIf="isModelFeedVisible"></div>
    <app-navpanel  *ngIf="isNavpanelShown" #navPanelRef [@navpanelAnimation]="navpanelAnimationState" (@navpanelAnimation.done)="onNavpanelAnimationDone($event)"  [navpanelActionItems]="homeNavpanelItems" (hideNavpanelEvent)="onHideNavpanelEventEmitted()" (navpanelActionClickEvent)="onNavpanelActionItemClicked($event)" (navpanelDestroyEvent)="onNavpanelDestroy()"></app-navpanel>

    <app-models-card @modelFeedAnimation #modelCardsFeed class="feed-overlap model-feed" [feedModificator]="{isSharingModelFeed: true}"  *ngIf="isModelFeedVisible" [startPosition]="modelFeedCardStartPosition"[feedIsVisible]="isModelFeedVisible" [cardsRaw]="modelFeedCards"  [feedMode]="CARDS_MODE.COMPOSITE_FEED" (cardPressed)="onModelCardPressed()" (cardClicked)="onModelCardClicked($event)"  (actionBtnClicked)="onCardCollectionControlBtnClicked($event)" (cardVisibilityChanged)="onCardVisibilityChanged($event)" ></app-models-card>

 </div>
