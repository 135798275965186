import { ActivatedRouteSnapshot, RouteReuseStrategy, DetachedRouteHandle } from '@angular/router';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false; // never detach any route
  }
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    // no-op
  }
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false; // always create new instance
  }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null; // no stored instance available
  }
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    // Force a reload if navigating between different model or collection IDs
    if (future.routeConfig?.path === 'model/:modelId' || future.routeConfig?.path === 'collection/:collectionId') {
      return false;
    }
    // Otherwise use default behavior
    return future.routeConfig === curr.routeConfig;
  }
}
