import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilityFunctionsService } from 'src/app/shared/providers/utility-functions.service';

export interface WebPoupupCopyLinkData {
  linkUrl: string;
  heading: string;
  mainBtnTitle: string;
  inputPlaceHolder: string;
  inputValue: string;
  isInputHidden?: boolean;
}

@Component({
  selector: 'app-web-popup-copylink',
  templateUrl: './web-popup-copylink.component.html',
  styleUrls: ['./web-popup-copylink.component.scss']
})
export class WebPopupCopylinkComponent implements OnInit {
  copyStatus = '';
  inputValue: string = '';
  heading: string = '';
  mainBtnTitle: string = '';
  inputPlaceHolder: string = '';
  isInputHidden: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WebPoupupCopyLinkData,
    public dialogRef: MatDialogRef<WebPopupCopylinkComponent>,
    private utility: UtilityFunctionsService
  ) {
    this.heading = data.heading;
    this.mainBtnTitle = data.mainBtnTitle;
    this.inputPlaceHolder = data.inputPlaceHolder;
    this.inputValue = data.inputValue;
    this.isInputHidden = data.isInputHidden;
  }

  ngOnInit(): void {
  }

  copyLink(): void {
    const performPostCopy = () => {
      this.copyStatus = 'Link copied to clipboard';
      this.dialogRef.close();
      this.utility.showImportantSnackBar('Link copied to clipboard', 100, 1000);
    };

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(this.data.linkUrl).then(() => {
        performPostCopy();
      }).catch(() => {
        // fallback to execCommand if writeText fails
        const textArea = document.createElement('textarea');
        textArea.value = this.data.linkUrl;
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.position = 'fixed';
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand('copy');
          performPostCopy();
        } catch (err) {
          console.error('Copy failed', err);
        }
        document.body.removeChild(textArea);
      });
    } else {
      // fallback for Safari: synchronous copy with execCommand
      const textArea = document.createElement('textarea');
      textArea.value = this.data.linkUrl;
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        performPostCopy();
      } catch (err) {
        console.error('Copy failed', err);
      }
      document.body.removeChild(textArea);
    }
  }
}
