import { NgModule } from '@angular/core';
import { RouterModule, Routes, ActivatedRouteSnapshot, RouterStateSnapshot, RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from './custom-route-reuse.strategy';
import { EditCollectionComponent } from './pages/edit-collection/edit-collection.component';
import { ServiceSettingsComponent } from './pages/service-settings/service-settings.component';
import { LibraryComponent } from './pages/library/library.component';
import { HomeComponent } from './pages/home/home.component';
import { Error404Component } from './pages/error404/error404.component';
import { AuthGuard } from './shared/providers/auth.guard';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { MobileSharedUserContentComponent } from './mobile/mobile-shared-user-content/mobile-shared-user-content.component';

const routes: Routes = [
  // {path: 'ourway', canActivate: [RedirectGuard], component: RedirectGuard, data: { externalUrl: 'https://about.3dway.io/'} },
  // {path: 'status3dwayio', canActivate: [RedirectGuard], component: RedirectGuard, data: { externalUrl: 'https://status.3dway.io'} },
  // {path: 'about3dwayio', canActivate: [RedirectGuard], component: RedirectGuard, data: { externalUrl: 'https://about.3dway.io/'} },
  // {path: 'careers3dwayio', canActivate: [RedirectGuard], component: RedirectGuard, data: { externalUrl: 'https://careers.3dway.io'} },
  {path: '', component: LibraryComponent, data: {animation: 'libraryPageAnimation'} },
  {path: 'home', component: HomeComponent, data: {animation: 'homePageAnimation'}},
  {path: 'model/:modelId', component: MobileSharedUserContentComponent },
  {path: 'collection/:collectionId', component: MobileSharedUserContentComponent },
  {path: 'library', component: EditCollectionComponent, data: {animation: 'editCollectionPageAnimation'}},

  {path: 'portfolio', component: PortfolioComponent, data: {animation: 'homePageAnimation'}},
  {path: 'service', component: ServiceSettingsComponent},

  {path: '404', component: Error404Component},
  // TEMPORARY REDIRECT TO TODAY SCREEN AS DEFAULT
  {path: '**', redirectTo: ''}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
  providers: [
    {
        provide: 'externalUrlRedirectResolver',
        useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
            window.location.href = (route.data as any).externalUrl;
        }
    },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy }
  ]
})
export class MobileRoutingModule {
}
