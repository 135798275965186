import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IThumbCard, ICard, CARD_PURCHASE_STATUS, SLIDER_CARD_TYPE } from 'src/app/models/card';
import { ICardCollection } from 'src/app/models/collection';
import { UploadFileService } from '../../providers/upload-file.service';
import { environment } from 'src/environments/environment';
import { CardsService } from '../../providers/cards.service';

@Component({
  selector: 'app-card-composite-slider',
  templateUrl: './card-composite-slider.component.html',
  styleUrls: ['./card-composite-slider.component.scss']
})
export class CardCompositeSliderComponent implements OnInit, OnChanges {
    @Input() title: string;
    @Input() publishRejectedReviewsCards: ICard[];
    @Input() sourceCollections: ICardCollection[];
    threeTimesArray = [0, 1, 2];

    CARD_PURCHASE_STATUS = CARD_PURCHASE_STATUS;
    SLIDER_CARD_TYPE = SLIDER_CARD_TYPE;

    // New property to hold filtered collections.
    filteredSourceCollections: ICardCollection[] = [];

    @Output() titleClicked = new EventEmitter<string>();
    @Output() publishRejectedReviewsGalleryClicked = new EventEmitter<boolean>();
    @Output() savedCollectionClicked = new EventEmitter<ICardCollection>();

    constructor(
      public uploadService: UploadFileService,
      private cardsService: CardsService
    ) { }

    ngOnInit(): void {
      // Optionally filter here in case sourceCollections is already provided at init.
      if(this.sourceCollections) {
        this.filterCollections();
      }
    }

    // FILTERING LOGIC - TEMPORARY WORKAROUND THAT SHOULD BE REMOVED IN FUTURE
    ngOnChanges(changes: SimpleChanges): void {
      if(changes['sourceCollections']) {
        this.filterCollections();
      }
    }

    // New asynchronous method to filter collections.
    async filterCollections(): Promise<void> {
      try {
        const homeFeed = await this.cardsService.getCollectionFeed();
        const homeFeedIds = homeFeed.map(col => col.collectionId);
        // Filter sourceCollections to include only those present in home feed.
        this.filteredSourceCollections = (this.sourceCollections || []).filter(coll => homeFeedIds.includes(coll.collectionId));
      } catch (error) {
        console.error('Error filtering collections:', error);
        // Fallback: show all if an error occurs.
        this.filteredSourceCollections = this.sourceCollections || [];
      }
    }

    titleClick(event: Event): void {
      console.log('titleclick')
      event.stopPropagation();
      this.titleClicked.emit(this.title);
    }

    hasButtonsInHeader(): boolean {
      if(this.title === 'Portfolio') return true;
      return false;
    }

    processcardPosterMobile(cardPosterMobile: string): string {
      if(cardPosterMobile === undefined || cardPosterMobile === null || cardPosterMobile === '') {
        return '/assets/images/gradientMock.png'
      }
      return cardPosterMobile
    }

    onPublishRejectedReviewThumbClicked(event: Event): void {
      event.stopPropagation();
      this.publishRejectedReviewsGalleryClicked.emit(true);
      return
    }

    onSavedCollectionThumbClicked(collectionThumb: ICardCollection, event: Event): void {
      event.stopPropagation();
      this.savedCollectionClicked.emit(collectionThumb);
    }

    public getMobileOrWebLayoutClass(): string {
      if(environment.type === 'mobile') return 'mobilelayout';
      if(environment.type === 'web') return 'weblayout';
      return '';
    }

    public isMobileView(): boolean {
      if(environment.type === 'mobile') return true;
      return false;
    }
}
