<section #cardsWrapperEl [ngClass]="{'model-card-wrapper': true, 'disable-sticky': isStickyDisabled, 'show-service':  settingService.showServicePageAndShowDebugDataDataOnCard}"  >
  <div class="no-data-label" *ngIf="cards?.length === 0">
    <mat-icon *ngIf="cards?.length === 0 && noDataMessage?.icon" [svgIcon]="noDataMessage.icon"></mat-icon>
    <!-- <h1 >{{getNoDataText()}}</h1> -->
  </div>

    <div #cardItemRef class="card-item" *ngFor="let card of cards; trackBy: trackByIndex" (tap)="onCardClicked(card, $event)"  (press)="onCardPress(card, $event)" (pressup)="onCardRelease(card, $event)" (panend)="onCardRelease(card, $event)"
    (visibilityChange)="onVisibilityChanged(card, $event)" appIntersectionObserver [intersectionDebounce]="30" [intersectionRootMargin]="calculatedRootMargin" [ngClass]="{'card-visible': card?.visible, 'shake' : isStickyDisabled}"
    >

      <img class="card-img poster-img" [ngClass]="card?.id" *ngIf="card?.isPosterShown" (load)="onImgLoad()" [src]="card?.[CORRECT_APP_POSTER] | formatImageExtension: settingService.devicePlatform">
      <app-card-video class="{{card?.cardTitle}}" *ngIf="card?.isVideoShown" (videoCanBePlayed)="onVideoCanBePlayed($event, card)" [videoSrc]="card?.[CORRECT_APP_CONTENT]" [posterSrc]="card?.[CORRECT_APP_POSTER]"></app-card-video>

      <div class="card-top-gradient" [ngClass]="{'transparent' : card?.cardShortView}"></div>

      <div class="card-inner-wrapper" [ngClass]="{'transparent' : card?.cardShortView, 'light' : isWebLayout}">

        <div class="card-description" *ngIf="(card?.cardShortView != true)">
          <div class="title-row" >
            <h1 class="card-title">{{card?.cardTitle}}</h1>
          </div>
          <div class="card-details-block" *ngIf="feedMode === CARDS_MODE.MODEL_CARD_FEED">
            <span class="detail-price">{{card?.cardCategory}}</span>
          </div>
          <div class="card-description-section" *ngIf="feedMode === CARDS_MODE.HOME_COLLECTION_FEED">
            <p class="card-about-short">{{card?.cardDescription}}</p>
          </div>
        </div>

        <div class="footer-row" >
          <button *ngIf="feedMode === CARDS_MODE.HOME_COLLECTION_FEED"  class="library-btn-left"  (click)="onLibraryBtnClick($event)" >
            <svg (click)="onLibraryBtnClick($event)" xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24" fill="none">
              <path d="M16.9167 4.2H5.08333M15.3333 1.5H6.66667M18 22.5H4C2.61929 22.5 1.5 21.3807 1.5 20L1.5 9.62673C1.5 8.24602 2.61929 7.12673 4 7.12673H18C19.3807 7.12673 20.5 8.24602 20.5 9.62673V20C20.5 21.3807 19.3807 22.5 18 22.5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span class="library-btn-text" (click)="onLibraryBtnClick($event)">Library</span>
          </button>
          <button *ngIf="feedMode !== CARDS_MODE.HOME_COLLECTION_FEED" class="back-btn" (click)="backBtnClick($event)" >
            <svg (click)="backBtnClick($event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span  class="back-btn-text" (click)="backBtnClick($event)">Back</span>
          </button>
          <button  *ngIf="feedMode === CARDS_MODE.HOME_COLLECTION_FEED" class="main-action-btn"  (click)="onGoToDetailsButtonClicked($event, card)"><span (click)="onGoToDetailsButtonClicked($event, card)">Open</span></button>
          <button  *ngIf="feedMode === CARDS_MODE.MODEL_CARD_FEED && card.purchaseStatus === CARDS_PURCHASE_STATUS.PURCHASED" class="main-action-btn" (click)="voidBtnNotImp($event)"><span (click)="voidBtnNotImp($event)">Download</span></button>
          <button  *ngIf="feedMode === CARDS_MODE.MODEL_CARD_FEED && card.purchaseStatus === CARDS_PURCHASE_STATUS.REVIEW_PUBLISH_REJECT" class="main-action-btn" [disabled]="true" (click)="voidBtn($event)"><span (click)="voidBtn($event)">Publish</span></button>
          <button  *ngIf="feedMode === CARDS_MODE.MODEL_CARD_FEED && card.purchaseStatus === CARDS_PURCHASE_STATUS.UPLOADED" class="main-action-btn" [disabled]="true" (click)="publishBtn($event, card)"><span (click)="publishBtn($event, card)">Publish</span></button>
          <button  *ngIf="feedMode === CARDS_MODE.MODEL_CARD_FEED && card.purchaseStatus === CARDS_PURCHASE_STATUS.DEFAULT_3DWAY" class="main-action-btn"  [disabled]="true" (click)="buyBtnClicked($event, card)">
            <span (click)="voidBtn($event)">Not for sale</span>
          </button>
          <!-- <button  *ngIf="feedMode === CARDS_MODE.MODEL_CARD_FEED && card.purchaseStatus === CARDS_PURCHASE_STATUS.DEFAULT_3DWAY" class="main-action-btn"  [disabled]="isCardInPurchaseProcess || !isCardInPurchaseProcess && !card?.cardPrice || card?.cardPrice === 0" (click)="buyBtnClicked($event, card)">

            <ng-container *ngIf="!isCardInPurchaseProcess"><span (click)="buyBtnClicked($event, card)">{{card?.cardPrice| priceformat}} </span></ng-container>
            <ng-container *ngIf="isCardInPurchaseProcess">
              &nbsp;
              <span (click)="buyBtnClicked($event, card)">
                <div class="circle-progress-bar-wrapper button-progress" >
                  <div class="pie spinner"></div>
                  <div class="pie backcolor"></div>
                </div>
              </span>
            </ng-container>
          </button> -->
          <button  *ngIf="feedMode === CARDS_MODE.EDIT_CARD" class="main-action-btn"  (click)="moveBtn($event,card)"><span (click)="moveBtn($event,card)">Move</span></button>

          <!-- COMPOSITE FEED -->
          <button  *ngIf="feedMode === CARDS_MODE.COMPOSITE_FEED && isPurchaseBtnAllowed(card.purchaseStatus)" class="main-action-btn"  [disabled]="isCardInPurchaseProcess || !isCardInPurchaseProcess && !card?.cardPrice || card?.cardPrice === 0" (click)="buyBtnClicked($event, card)">
            <ng-container *ngIf="!isCardInPurchaseProcess"><span (click)="buyBtnClicked($event, card)">{{card?.cardPrice| priceformat}} </span></ng-container>
            <ng-container *ngIf="isCardInPurchaseProcess">
              &nbsp;
              <span (click)="buyBtnClicked($event, card)">
                <div class="circle-progress-bar-wrapper button-progress" >
                  <div class="pie spinner"></div>
                  <div class="pie backcolor"></div>
                </div>
              </span>
            </ng-container>
          </button>
          <button  *ngIf="feedMode === CARDS_MODE.COMPOSITE_FEED && card.purchaseStatus === CARDS_PURCHASE_STATUS.PURCHASED && environment.production" class="main-action-btn" (click)="voidBtnNotImp($event)"><span (click)="voidBtnNotImp($event)">Download</span></button>
          <a       *ngIf="feedMode === CARDS_MODE.COMPOSITE_FEED && card.purchaseStatus === CARDS_PURCHASE_STATUS.PURCHASED && !environment.production" class="download-btn" [href]="cardService.usdzHref">Download</a>
          <button  *ngIf="feedMode === CARDS_MODE.COMPOSITE_FEED && card.purchaseStatus === CARDS_PURCHASE_STATUS.IN_REVIEW" class="main-action-btn" (click)="voidBtn($event)"><span (click)="voidBtn($event)">In Review</span></button>
          <button  *ngIf="feedMode === CARDS_MODE.COMPOSITE_FEED && card.purchaseStatus === CARDS_PURCHASE_STATUS.REVIEW_PUBLISH_REJECT" class="main-action-btn" [disabled]="true" (click)="voidBtn($event)"><span (click)="voidBtn($event)">Publish</span></button>
          <button  *ngIf="feedMode === CARDS_MODE.COMPOSITE_FEED && card.purchaseStatus === CARDS_PURCHASE_STATUS.REJECTED" class="main-action-btn" (click)="rejectedBtn($event, card)"><span (click)="rejectedBtn($event, card)">Rejected</span></button>
          <button  *ngIf="feedMode === CARDS_MODE.COMPOSITE_FEED && card.purchaseStatus === CARDS_PURCHASE_STATUS.PUBLISHED" class="main-action-btn" (click)="voidBtn($event)" [disabled]="true"><span class="top-tag" (click)="voidBtn($event)">Analytics<span class="bottom-tag">Coming soon</span> </span></button>
          <button  *ngIf="feedMode === CARDS_MODE.COMPOSITE_FEED && card.purchaseStatus === CARDS_PURCHASE_STATUS.UPLOADED" class="main-action-btn disabled" (click)="publishBtn($event, card)"  ><span (click)="publishBtn($event, card)">Publish</span></button>
          <button  *ngIf="feedMode === CARDS_MODE.COMPOSITE_FEED && card.purchaseStatus === undefined && feedModificator?.isSharingModelFeed" class="main-action-btn disabled"><span>Save</span></button>

          <!-- TEMPORARY DISABLE PUBLISH FOR USER MODELS -->
          <!-- END -->



          <button  class="search-btn" [disabled]="true" *ngIf="feedMode === CARDS_MODE.HOME_COLLECTION_FEED" (click)="onSearchBtnClick(card, $event)" >
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" (click)="onSearchBtnClick(card, $event)">
              <path d="M0.75 8.9375C0.75 13.4593 4.41567 17.125 8.9375 17.125C13.4593 17.125 17.125 13.4593 17.125 8.9375C17.125 4.41567 13.4593 0.75 8.9375 0.75C4.41567 0.75 0.75 4.41567 0.75 8.9375Z" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
              <path d="M20.9824 20.9687L15.3558 15.355" stroke="white" stroke-width="2" stroke-linecap="round"/>
              <circle xmlns="http://www.w3.org/2000/svg" cx="9" cy="9" r="5" />
            </svg>
            <span class="search-btn-text" (click)="onSearchBtnClick(card, $event)">Search</span>
          </button>
          <button *ngIf="isOptionsBtnVisible()" class="options-navbar-btn" (click)="optionsBtnClick(card, $event)">
            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
              <circle cx="13" cy="21" r="2" fill="white"/>
              <circle cx="21" cy="21" r="2" fill="white"/>
              <circle cx="29" cy="21" r="2" fill="white"/>
            </svg>
            <span  class="options-btn-text" (click)="optionsBtnClick(card, $event)">More</span>
          </button>
          <button *ngIf="feedMode !== CARDS_MODE.HOME_COLLECTION_FEED && !isOptionsBtnVisible()" class="options-navbar-btn" (click)="voidBtn($event)">
          </button>
          <!-- <button *ngIf="feedMode !== CARDS_MODE.DEFAULT_MODE" class="top-btn" (click)="nativeScrollToTop()">
            <svg width="20" height="27" viewBox="0 0 20 27" fill="none" xmlns="http://www.w3.org/2000/svg" >
              <path d="M16.3516 12.5781L10.0625 7.54687L3.77344 12.5781" stroke="#BDBDBD" stroke-width="1.83333" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.3516 19.5781L10.0625 14.5469L3.77344 19.5781" stroke="#BDBDBD" stroke-width="1.83333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span  class="top-btn-text">Top</span>
          </button> -->

          <!-- <button [ngClass]="{'back-btn' : true, 'active' : !isBackActive, 'not-active': isBackActive}" disableRipple color="primary" (click)="backBtnClick($event)" ></button> -->
          <!-- <button fxFlex="0 1 120px" *ngIf="mode === CARDS_MODE.DEFAULT_MODE" [ngClass]="{'open-btn' : true, 'active' : !isOpenActive, 'not-active': isOpenActive}" disableRipple mat-flat-button (click)="onGoToDetailsButtonClicked($event, card)"><span (click)="onGoToDetailsButtonClicked($event, card)">Open</span></button> -->
          <!-- <button fxFlex="0 1 120px" *ngIf="mode === CARDS_MODE.MODEL_CARD_FEED" [ngClass]="{'price-btn' : true, 'active' : !isOpenActive, 'not-active': isOpenActive}" disableRipple mat-flat-button (click)="voidBtn($event)"><span (click)="voidBtn($event)">$149.99 <span class="collection-tag">Collection</span> </span></button> -->
          <!-- <button fxFlex="1 1 50" [ngClass]="{'home-btn' : true, 'active' : isHomeActive, 'not-active': !isHomeActive}"  disableRipple color="primary" mat-icon-button (click)="onHomeBtnClick($event)" > -->
        </div>

        <div class="button-column-wrapper"  *ngIf="(card?.cardShortView != true)" fxLayout="column-reverse" fxLayoutAlign="space-between center">
            <div class="circle-progress-bar-wrapper bottom-progress" [@fadeWithDelay] *ngIf="!card?.canBePlayed && cardService.getCurrentCard()?.[CORRECT_APP_CONTENT] === card?.[CORRECT_APP_CONTENT]">
              <div class="pie spinner"></div>
              <div class="pie backcolor"></div>
            </div>
            <!-- <button class="options-btn column-btn" *ngIf="isOptionsBtnVisible()" (click)="optionsBtn($event, card)">
              <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="13.5" cy="21" r="2" fill="white"/>
                <circle cx="21.5" cy="21" r="2" fill="white"/>
                <circle cx="29.5" cy="21" r="2" fill="white"/>
              </svg>
            </button> -->
            <button class="share-btn column-btn"  *ngIf="isShareBtnVisible()" (click)="onShareBtnClicked(card, $event)"  >
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="24" viewBox="0 0 19 24" fill="none">
                <path d="M3.33637 24H15.6638C16.7401 24 17.5519 23.7265 18.0992 23.1795C18.6464 22.6324 18.9201 21.8256 18.9201 20.7589V9.95557C18.9201 8.8889 18.6464 8.08435 18.0992 7.54189C17.5519 6.99946 16.7401 6.72824 15.6638 6.72824H12.6401V8.24618H15.6364C16.1928 8.24618 16.6261 8.39432 16.9362 8.69062C17.2463 8.98691 17.4014 9.43135 17.4014 10.0239V20.6906C17.4014 21.2832 17.2463 21.7276 16.9362 22.0239C16.6261 22.3202 16.1928 22.4683 15.6364 22.4683H3.36374C2.79821 22.4683 2.36267 22.3202 2.05712 22.0239C1.75154 21.7276 1.59876 21.2832 1.59876 20.6906V10.0239C1.59876 9.43135 1.75154 8.98691 2.05712 8.69062C2.36267 8.39432 2.79821 8.24618 3.36374 8.24618H6.36006V6.72824H3.33637C2.26006 6.72824 1.44827 6.99718 0.90099 7.53506C0.353716 8.07296 0.0800781 8.87979 0.0800781 9.95557V20.7589C0.0800781 21.8347 0.353716 22.6438 0.90099 23.1863C1.44827 23.7287 2.26006 24 3.33637 24Z" fill="white"/>
                <path d="M9.49305 15.6717C9.70283 15.6717 9.88297 15.6011 10.0335 15.4598C10.184 15.3185 10.2592 15.143 10.2592 14.9333V3.66496L10.1908 2.07863L11.0254 2.96751L12.763 4.77262C12.8907 4.92761 13.0595 5.00511 13.2693 5.00511C13.4699 5.00511 13.6364 4.94129 13.7687 4.81365C13.9009 4.68602 13.967 4.52647 13.967 4.33502C13.967 4.1527 13.8895 3.98859 13.7345 3.84272L10.0403 0.246151C9.9491 0.154979 9.86017 0.0911611 9.77353 0.0546963C9.68687 0.0182316 9.59338 0 9.49305 0C9.40183 0 9.31289 0.0182316 9.22623 0.0546963C9.13959 0.0911611 9.0461 0.154979 8.94575 0.246151L5.25164 3.84272C5.09658 3.98859 5.01904 4.1527 5.01904 4.33502C5.01904 4.52647 5.08289 4.68602 5.21059 4.81365C5.33829 4.94129 5.50703 5.00511 5.71681 5.00511C5.92661 5.00511 6.09992 4.92761 6.23674 4.77262L7.96065 2.96751L8.80895 2.07863L8.74052 3.66496V14.9333C8.74052 15.143 8.81578 15.3185 8.96629 15.4598C9.11679 15.6011 9.29238 15.6717 9.49305 15.6717Z" fill="white"/>
              </svg>
            </button>
            <button class="like-btn column-btn" *ngIf="isLikeBtnVisible(card) && !isLikeHidden" [ngClass]="{'active' : isSaved(card) === true, 'not-active': isSaved(card) ===  false}" (click)="onLikeButtonClicked(card, $event)" >
              <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.5 21.75C17.1609 21.75 21.75 17.1609 21.75 11.5C21.75 5.83908 17.1609 1.25 11.5 1.25C5.83908 1.25 1.25 5.83908 1.25 11.5C1.25 17.1609 5.83908 21.75 11.5 21.75ZM22.25 11.5C22.25 17.4371 17.4371 22.25 11.5 22.25C5.56294 22.25 0.75 17.4371 0.75 11.5C0.75 5.56294 5.56294 0.75 11.5 0.75C17.4371 0.75 22.25 5.56294 22.25 11.5Z" fill="white" stroke="white" stroke-width="0.8"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4785 6.01627C12.0308 6.01627 12.4785 6.46398 12.4785 7.01627L12.4785 15.9837C12.4785 16.5359 12.0308 16.9837 11.4785 16.9837C10.9262 16.9837 10.4785 16.5359 10.4785 15.9837L10.4785 7.01627C10.4785 6.46398 10.9262 6.01627 11.4785 6.01627Z" fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.99445 11.4816C5.99536 10.9293 6.44381 10.4823 6.99609 10.4832L15.9635 10.498C16.5158 10.4989 16.9627 10.9473 16.9618 11.4996C16.9609 12.0519 16.5125 12.4989 15.9602 12.498L6.9928 12.4832C6.44052 12.4823 5.99354 12.0338 5.99445 11.4816Z" fill="white"/>
              </svg>

              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5C0 17.8513 5.14873 23 11.5 23ZM11.8032 15.7866C11.5903 16.0964 11.3195 16.2513 10.9905 16.2513C10.8164 16.2513 10.6616 16.2174 10.5262 16.1496C10.3907 16.0819 10.2601 15.9609 10.1344 15.7866L7.23216 12.2582C7.07739 12.0549 7 11.8468 7 11.6338C7 11.4112 7.07497 11.2224 7.22491 11.0676C7.37487 10.9127 7.5611 10.8352 7.78359 10.8352C7.91903 10.8352 8.04479 10.8643 8.16087 10.9223C8.27697 10.9804 8.39789 11.0869 8.52364 11.2418L10.947 14.3056L15.8953 6.45013C16.0791 6.15004 16.3064 6 16.5773 6C16.7901 6 16.9812 6.06776 17.1505 6.20329C17.3198 6.33881 17.4044 6.51789 17.4044 6.74052C17.4044 6.85669 17.3778 6.96802 17.3246 7.0745C17.2714 7.18098 17.2158 7.28262 17.1577 7.37943L11.8032 15.7866Z" fill="white"/>
              </svg>



            </button>
            <!-- TEMPORARY DISABLE BEFORE NEW LOGIC ARRIVE -->
            <!-- <a class="download-btn column-btn" [href]="getCardLink(card)" *ngIf="isDownloadBtnVisible(card) && getCardLink(card) !== 'N/A'" >
              <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.4927 4.3869e-05C13.0696 4.3869e-05 14.5522 0.297667 15.9404 0.892912C17.3286 1.48816 18.5524 2.31811 19.6117 3.38277C20.671 4.44744 21.5005 5.67181 22.1003 7.05587C22.7001 8.43993 23 9.92079 23 11.4984C23 13.0761 22.7001 14.5569 22.1003 15.941C21.5005 17.325 20.6686 18.5494 19.6044 19.6141C18.5403 20.6787 17.3165 21.5087 15.9331 22.104C14.5497 22.6992 13.0648 22.9968 11.4782 22.9968C9.91104 22.9968 8.43333 22.6992 7.04511 22.104C5.6569 21.5087 4.43555 20.6787 3.38108 19.6141C2.3266 18.5494 1.49947 17.325 0.899684 15.941C0.299895 14.5569 0 13.0761 0 11.4984C0 9.92079 0.299895 8.43993 0.899684 7.05587C1.49947 5.67181 2.32902 4.44744 3.38833 3.38277C4.44764 2.31811 5.6714 1.48816 7.05962 0.892912C8.44785 0.297667 9.92556 4.3869e-05 11.4927 4.3869e-05ZM11.4927 1.69867C10.1384 1.69867 8.86867 1.95032 7.68359 2.45361C6.49853 2.95691 5.45857 3.65863 4.56373 4.55876C3.66888 5.45888 2.96993 6.50177 2.46687 7.68741C1.96383 8.87307 1.71232 10.1434 1.71232 11.4984C1.71232 12.8535 1.96383 14.1238 2.46687 15.3095C2.96993 16.4951 3.66888 17.538 4.56373 18.4381C5.45857 19.3383 6.49611 20.04 7.67634 20.5432C8.85656 21.0465 10.1239 21.2982 11.4782 21.2982C12.8423 21.2982 14.1168 21.0465 15.3019 20.5432C16.487 20.04 17.5269 19.3383 18.4218 18.4381C19.3166 17.538 20.0204 16.4951 20.5331 15.3095C21.0458 14.1238 21.3022 12.8535 21.3022 11.4984C21.3022 10.1434 21.0483 8.87307 20.5404 7.68741C20.0325 6.50177 19.3311 5.45888 18.4363 4.55876C17.5414 3.65863 16.5015 2.95691 15.3164 2.45361C14.1313 1.95032 12.8568 1.69867 11.4927 1.69867Z" fill="#E0E0E0"/>
                <path d="M11.4912 5.83637C11.7137 5.83637 11.8951 5.90654 12.0354 6.04688C12.1756 6.18724 12.2458 6.36872 12.2458 6.59133V12.747L12.1732 14.9974L13.3776 13.6181L14.8578 12.1082C15.0029 11.9631 15.1819 11.8905 15.3947 11.8905C15.6075 11.8905 15.7841 11.9582 15.9243 12.0937C16.0646 12.2292 16.1347 12.3986 16.1347 12.6018C16.1347 12.8148 16.0622 12.989 15.9171 13.1245L12.0716 16.9718C11.8878 17.1654 11.6944 17.2622 11.4912 17.2622C11.288 17.2622 11.0946 17.1654 10.9108 16.9718L7.05081 13.1245C6.91538 12.989 6.84766 12.8148 6.84766 12.6018C6.84766 12.3986 6.91779 12.2292 7.05806 12.0937C7.19834 11.9582 7.37005 11.8905 7.57321 11.8905C7.78603 11.8905 7.96501 11.9631 8.11013 12.1082L9.61928 13.6181L10.8092 14.9829L10.7221 12.747V6.59133C10.7221 6.36872 10.7947 6.18724 10.9398 6.04688C11.0849 5.90654 11.2687 5.83637 11.4912 5.83637Z" fill="white"/>
              </svg>
            </a> -->
            <!-- MOCKED DOWNLOAD COMING SOON -->
            <button class="download-btn column-btn" *ngIf="isDownloadBtnVisible(card) && getCardLink(card) !== 'N/A'" (click)="downloadBtnMock($event, card)" >
              <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.4927 4.3869e-05C13.0696 4.3869e-05 14.5522 0.297667 15.9404 0.892912C17.3286 1.48816 18.5524 2.31811 19.6117 3.38277C20.671 4.44744 21.5005 5.67181 22.1003 7.05587C22.7001 8.43993 23 9.92079 23 11.4984C23 13.0761 22.7001 14.5569 22.1003 15.941C21.5005 17.325 20.6686 18.5494 19.6044 19.6141C18.5403 20.6787 17.3165 21.5087 15.9331 22.104C14.5497 22.6992 13.0648 22.9968 11.4782 22.9968C9.91104 22.9968 8.43333 22.6992 7.04511 22.104C5.6569 21.5087 4.43555 20.6787 3.38108 19.6141C2.3266 18.5494 1.49947 17.325 0.899684 15.941C0.299895 14.5569 0 13.0761 0 11.4984C0 9.92079 0.299895 8.43993 0.899684 7.05587C1.49947 5.67181 2.32902 4.44744 3.38833 3.38277C4.44764 2.31811 5.6714 1.48816 7.05962 0.892912C8.44785 0.297667 9.92556 4.3869e-05 11.4927 4.3869e-05ZM11.4927 1.69867C10.1384 1.69867 8.86867 1.95032 7.68359 2.45361C6.49853 2.95691 5.45857 3.65863 4.56373 4.55876C3.66888 5.45888 2.96993 6.50177 2.46687 7.68741C1.96383 8.87307 1.71232 10.1434 1.71232 11.4984C1.71232 12.8535 1.96383 14.1238 2.46687 15.3095C2.96993 16.4951 3.66888 17.538 4.56373 18.4381C5.45857 19.3383 6.49611 20.04 7.67634 20.5432C8.85656 21.0465 10.1239 21.2982 11.4782 21.2982C12.8423 21.2982 14.1168 21.0465 15.3019 20.5432C16.487 20.04 17.5269 19.3383 18.4218 18.4381C19.3166 17.538 20.0204 16.4951 20.5331 15.3095C21.0458 14.1238 21.3022 12.8535 21.3022 11.4984C21.3022 10.1434 21.0483 8.87307 20.5404 7.68741C20.0325 6.50177 19.3311 5.45888 18.4363 4.55876C17.5414 3.65863 16.5015 2.95691 15.3164 2.45361C14.1313 1.95032 12.8568 1.69867 11.4927 1.69867Z" fill="#E0E0E0"/>
                <path d="M11.4912 5.83637C11.7137 5.83637 11.8951 5.90654 12.0354 6.04688C12.1756 6.18724 12.2458 6.36872 12.2458 6.59133V12.747L12.1732 14.9974L13.3776 13.6181L14.8578 12.1082C15.0029 11.9631 15.1819 11.8905 15.3947 11.8905C15.6075 11.8905 15.7841 11.9582 15.9243 12.0937C16.0646 12.2292 16.1347 12.3986 16.1347 12.6018C16.1347 12.8148 16.0622 12.989 15.9171 13.1245L12.0716 16.9718C11.8878 17.1654 11.6944 17.2622 11.4912 17.2622C11.288 17.2622 11.0946 17.1654 10.9108 16.9718L7.05081 13.1245C6.91538 12.989 6.84766 12.8148 6.84766 12.6018C6.84766 12.3986 6.91779 12.2292 7.05806 12.0937C7.19834 11.9582 7.37005 11.8905 7.57321 11.8905C7.78603 11.8905 7.96501 11.9631 8.11013 12.1082L9.61928 13.6181L10.8092 14.9829L10.7221 12.747V6.59133C10.7221 6.36872 10.7947 6.18724 10.9398 6.04688C11.0849 5.90654 11.2687 5.83637 11.4912 5.83637Z" fill="white"/>
              </svg>
            </button>
            <!-- MOCKED DOWNLOAD COMING SOON END -->

            <button class="download-btn column-btn" *ngIf="isDownloadBtnVisible(card) && getCardLink(card) === 'N/A'" (click)="noLinkClick($event)" >
              <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.4927 4.3869e-05C13.0696 4.3869e-05 14.5522 0.297667 15.9404 0.892912C17.3286 1.48816 18.5524 2.31811 19.6117 3.38277C20.671 4.44744 21.5005 5.67181 22.1003 7.05587C22.7001 8.43993 23 9.92079 23 11.4984C23 13.0761 22.7001 14.5569 22.1003 15.941C21.5005 17.325 20.6686 18.5494 19.6044 19.6141C18.5403 20.6787 17.3165 21.5087 15.9331 22.104C14.5497 22.6992 13.0648 22.9968 11.4782 22.9968C9.91104 22.9968 8.43333 22.6992 7.04511 22.104C5.6569 21.5087 4.43555 20.6787 3.38108 19.6141C2.3266 18.5494 1.49947 17.325 0.899684 15.941C0.299895 14.5569 0 13.0761 0 11.4984C0 9.92079 0.299895 8.43993 0.899684 7.05587C1.49947 5.67181 2.32902 4.44744 3.38833 3.38277C4.44764 2.31811 5.6714 1.48816 7.05962 0.892912C8.44785 0.297667 9.92556 4.3869e-05 11.4927 4.3869e-05ZM11.4927 1.69867C10.1384 1.69867 8.86867 1.95032 7.68359 2.45361C6.49853 2.95691 5.45857 3.65863 4.56373 4.55876C3.66888 5.45888 2.96993 6.50177 2.46687 7.68741C1.96383 8.87307 1.71232 10.1434 1.71232 11.4984C1.71232 12.8535 1.96383 14.1238 2.46687 15.3095C2.96993 16.4951 3.66888 17.538 4.56373 18.4381C5.45857 19.3383 6.49611 20.04 7.67634 20.5432C8.85656 21.0465 10.1239 21.2982 11.4782 21.2982C12.8423 21.2982 14.1168 21.0465 15.3019 20.5432C16.487 20.04 17.5269 19.3383 18.4218 18.4381C19.3166 17.538 20.0204 16.4951 20.5331 15.3095C21.0458 14.1238 21.3022 12.8535 21.3022 11.4984C21.3022 10.1434 21.0483 8.87307 20.5404 7.68741C20.0325 6.50177 19.3311 5.45888 18.4363 4.55876C17.5414 3.65863 16.5015 2.95691 15.3164 2.45361C14.1313 1.95032 12.8568 1.69867 11.4927 1.69867Z" fill="#E0E0E0"/>
                <path d="M11.4912 5.83637C11.7137 5.83637 11.8951 5.90654 12.0354 6.04688C12.1756 6.18724 12.2458 6.36872 12.2458 6.59133V12.747L12.1732 14.9974L13.3776 13.6181L14.8578 12.1082C15.0029 11.9631 15.1819 11.8905 15.3947 11.8905C15.6075 11.8905 15.7841 11.9582 15.9243 12.0937C16.0646 12.2292 16.1347 12.3986 16.1347 12.6018C16.1347 12.8148 16.0622 12.989 15.9171 13.1245L12.0716 16.9718C11.8878 17.1654 11.6944 17.2622 11.4912 17.2622C11.288 17.2622 11.0946 17.1654 10.9108 16.9718L7.05081 13.1245C6.91538 12.989 6.84766 12.8148 6.84766 12.6018C6.84766 12.3986 6.91779 12.2292 7.05806 12.0937C7.19834 11.9582 7.37005 11.8905 7.57321 11.8905C7.78603 11.8905 7.96501 11.9631 8.11013 12.1082L9.61928 13.6181L10.8092 14.9829L10.7221 12.747V6.59133C10.7221 6.36872 10.7947 6.18724 10.9398 6.04688C11.0849 5.90654 11.2687 5.83637 11.4912 5.83637Z" fill="white"/>
              </svg>
            </button>
        </div>
      </div>
    </div>




</section>
