import { ICollaborator } from './../../models/collaboration';
import { DEFAULT_USER_COLLECTION_ID, ICardCollection, ICardCollectionMockSource } from 'src/app/models/collection';
import { CARD_PURCHASE_STATUS, ICardMockSource, IThumbCard, ModelCardService, SLIDER_CARD_TYPE } from './../../models/card';
import { Injectable, OnInit } from '@angular/core';
import { Router, UrlTree, UrlSegment } from '@angular/router';
import { CARD_TYPE, ICard, ModelCardRendered } from 'src/app/models/card';
import { environment } from 'src/environments/environment';

const mockBaseUrl = 'storage-dev.3dway.io';

@Injectable({
  providedIn: 'root'
})
export class MockDataService {

  constructor(private router: Router) {
    this.mockCardsDiscovery = this.fillMockedCardsDiscoverFeed();
  }

  urlTree: UrlTree;
  urlSegments: UrlSegment[];

  public sampleUSDZmodelGSpathPreview: string = 'gs://storage-3dway-dev/users/default/sample_model.usdz';
  public genericMockCardNameBasis: string = `https://${mockBaseUrl}/preview/HLS_50_2500_/`;
  public mockCardsDiscovery: ModelCardService[] = [];

  public modelCardsMockSourceShort: ModelCardRendered[] = [{
    id: 'N/A',
    modifiedDate: (new Date()).toString(),
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/02/02_1688_1750k.mp4`,
    cardPosterWeb: '',
    cardContentWeb: '',
    cardType : CARD_TYPE.MODEL,
    cardCategory: 'Sculpture',
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 1',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/02/02_poster.webp`,
    cardPrice: this.generateRandomPrice(),
    purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
  },
  {
    id: 'N/A',
    modifiedDate: (new Date()).toString(),
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/03/03_1688_1750k.mp4`,
    cardPosterWeb: '',
    cardContentWeb: '',
    cardType: CARD_TYPE.MODEL,
    cardCategory: 'Sculpture',
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 2',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/03/03_poster.webp`,
    cardPrice: this.generateRandomPrice(),
    purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
  }, {
    id: 'N/A',
    modifiedDate: (new Date()).toString(),
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/04/04_1688_1750k.mp4`,
    cardPosterWeb: '',
    cardContentWeb: '',
    cardType : CARD_TYPE.MODEL,
    cardCategory: 'Sculpture',
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 3',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/04/04_poster.webp`,
    cardPrice: this.generateRandomPrice(),
    purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
  }, {
    id: 'N/A',
    modifiedDate: (new Date()).toString(),
    cardPosterWeb: '',
    cardContentWeb: '',
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/05/05_1688_1750k.mp4`,
    cardType: CARD_TYPE.MODEL,
    cardCategory: 'Sculpture',
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 4',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/05/05_poster.webp`,
    cardPrice: this.generateRandomPrice(),
    purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
  }, {
    id: 'N/A',
    modifiedDate: (new Date()).toString(),
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/06/06_1688_1750k.mp4`,
    cardPosterWeb: '',
    cardContentWeb: '',
    cardType: CARD_TYPE.MODEL,
    cardCategory: 'Sculpture',
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 5',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/06/06_poster.webp`,
    cardPrice: this.generateRandomPrice(),
    purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
  }, {
    id: 'N/A',
    modifiedDate: (new Date()).toString(),
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/07/07_1688_1750k.mp4`,
    cardPosterWeb: '',
    cardContentWeb: '',
    cardType: CARD_TYPE.MODEL,
    cardCategory: 'Sculpture',
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 6',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/07/07_poster.webp`,
    cardPrice: this.generateRandomPrice(),
    purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
  }, {
    id: 'N/A',
    modifiedDate: (new Date()).toString(),
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/08/08_1688_1750k.mp4`,
    cardPosterWeb: '',
    cardContentWeb: '',
    cardType: CARD_TYPE.MODEL,
    cardCategory: 'Sculpture',
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 7',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/08/08_poster.webp`,
    cardPrice: this.generateRandomPrice(),
    purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
  },
  {
    id: 'N/A',
    modifiedDate: (new Date()).toString(),
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/09/09_1688_1750k.mp4`,
    cardPosterWeb: '',
    cardContentWeb: '',
    cardType: CARD_TYPE.MODEL,
    cardCategory: 'Sculpture',
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 8',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/09/09_poster.webp`,
    cardPrice: this.generateRandomPrice(),
    purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
  },
  {
    id: 'N/A',
    modifiedDate: (new Date()).toString(),
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/10/10_1688_1750k.mp4`,
    cardPosterWeb: '',
    cardContentWeb: '',
    cardType: CARD_TYPE.MODEL,
    cardCategory: 'Sculpture',
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 9',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/10/10_poster.webp`,
    cardPrice: this.generateRandomPrice(),
    purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
  },
];

public mockPublishedModels = [
  {
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/09/09_1688_1750k.mp4`,
    cardType: CARD_TYPE.MODEL,
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 8',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/09/09_poster.webp`,
    cardPrice: 0,
  },
  {
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/07/07_1688_1750k.mp4`,
    cardType: CARD_TYPE.MODEL,
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 6',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/07/07_poster.webp`,
    cardPrice: 0
  },
  {
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/06/06_1688_1750k.mp4`,
    cardType: CARD_TYPE.MODEL,
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 5',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/06/06_poster.webp`,
    cardPrice: 0
  },
  {
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/04/04_1688_1750k.mp4`,
    cardType : CARD_TYPE.MODEL,
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 3',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/04/04_poster.webp`,
    cardPrice: 0
  },
  {
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/02/02_1688_1750k.mp4`,
    cardType : CARD_TYPE.MODEL,
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 2',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/02/02_poster.webp`,
    cardPrice: 0
  }
]

public mockPurchasedModels = [
  {
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/03/03_1688_1750k.mp4`,
    cardType: CARD_TYPE.MODEL,
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 2',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/03/03_poster.webp`,
    cardPrice: 0
  },
  {
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/08/08_1688_1750k.mp4`,
    cardType: CARD_TYPE.MODEL,
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 7',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/08/08_poster.webp`,
    cardPrice: 0
  },
  {
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/10/10_1688_1750k.mp4`,
    cardType: CARD_TYPE.MODEL,
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 9',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/10/10_poster.webp`,
    cardPrice: 0
  },
  {
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/05/05_1688_1750k.mp4`,
    cardType: CARD_TYPE.MODEL,
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 4',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/05/05_poster.webp`,
    cardPrice: 0
  }
]

public basicCollectionVideo = `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/LIGHNING_conv_videoplayback3/LIGHNING_conv_videoplayback3_1688_1750k.mp4`;
public basiccollectionPosterMobile = `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/LIGHNING_conv_videoplayback3/LIGHNING_conv_videoplayback3_poster.webp`;

public collaboratorsMock: ICollaborator[] = [
  {
    id: '1',
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/26/26_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/31/31_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/32/32_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/33/33_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/34/34_poster.webp`,

      ], '_1628_2500k.mp4')
  },
  {
    id: '2',
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/30/30_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/01/01_poster.webp`,

      ], '_1628_2500k.mp4')
  },
  {
    id: '3',
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/02/02_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/03/03_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/04/04_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/05/05_poster.webp`,


      ], '_1628_2500k.mp4')
  },
  {
    id: '4',
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/06/06_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/07/07_poster.webp`,

      ], '_1628_2500k.mp4')
  }
]

public collaboratorsMock2: ICollaborator[] = [
  {
    id: '1',
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/11/11_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/12/12_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/13/13_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/14/14_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/15/15_poster.webp`,

      ], '_1628_2500k.mp4')
  },
  {
    id: '2',
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/09/09_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/08/08_poster.webp`,

      ], '_1628_2500k.mp4')
  },
  {
    id: '3',
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/42/42_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/43/43_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/44/44_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/45/45_poster.webp`,


      ], '_1628_2500k.mp4')
  },
  {
    id: '4',
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/36/36_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/37/37_poster.webp`,

      ], '_1628_2500k.mp4')
  }
]

public collaboratorsList: ICollaborator[] = [
  {
    id: this.generateMockCollectionId(),
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/02/02_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/03/03_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/04/04_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/05/05_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/11/11_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/12/12_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/13/13_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/14/14_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/15/15_poster.webp`,

      ], '_1628_2500k.mp4')
  },
  {
    id: this.generateMockCollectionId(),
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/09/09_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/08/08_poster.webp`,

      ], '_1628_2500k.mp4')
  },
  {
    id: this.generateMockCollectionId(),
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/42/42_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/43/43_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/44/44_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/45/45_poster.webp`,


      ], '_1628_2500k.mp4')
  },
  {
    id: this.generateMockCollectionId(),
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/36/36_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/37/37_poster.webp`,

      ], '_1628_2500k.mp4')
  },
  {
    id: this.generateMockCollectionId(),
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/20/20_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/21/21_poster.webp`,

      ], '_1628_2500k.mp4')
  },
  {
    id: this.generateMockCollectionId(),
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/17/17_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/18/18_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/19/19_poster.webp`,

      ], '_1628_2500k.mp4')
  },
  {
    id: this.generateMockCollectionId(),
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/06/06_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/07/07_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/10/10_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/23/23_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/24/24_poster.webp`,


      ], '_1628_2500k.mp4')
  }
]



  public thumbLikedCards: string[] = [
    `https://${mockBaseUrl}/preview/HLS_50_2500_/01/01_poster.webp`,
    `https://${mockBaseUrl}/preview/HLS_50_2500_/03/03_poster.webp`,
    `https://${mockBaseUrl}/preview/HLS_50_2500_/05/05_poster.webp`,
    `https://${mockBaseUrl}/preview/HLS_50_2500_/06/06_poster.webp`,
    `https://${mockBaseUrl}/preview/HLS_50_2500_/09/09_poster.webp`,
    `https://${mockBaseUrl}/preview/HLS_50_2500_/13/13_poster.webp`,
  ]





  public editCollectioncardSelectData = this.generateModelCardRenderedMocksBasedOnImgUrl([
    `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/02/02_poster.webp`,
    `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/03/03_poster.webp`,
    `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/04/04_poster.webp`,
    `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/05/05_poster.webp`,
    `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/06/06_poster.webp`,
    `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/07/07_poster.webp`,
    `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/08/08_poster.webp`,
    `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/09/09_poster.webp`,
    `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/10/10_poster.webp`,
  ])


  public fillMockedCardsDiscoverFeed(): ModelCardService[] {
    const mockCardsDiscovery: ModelCardService[] = this.mockCardsDiscovery;

    return mockCardsDiscovery
  }

  public getMockedCardsDiscoverFeed(): ICard[] {
  return this.mockCardsDiscovery.filter((card) => {
    return card.showInMock === true;
  })
  }

  public getMockedCardsFollowingFeed(): ICard[] {
    const mockCardsFeed: ICard[] = [];

    // Example to compare hires vs lowres;
    mockCardsFeed.push({
      id: 'N/A',
      modifiedDate: (new Date()).toString(),
      cardContentMobile: '/assets/images/cards/model_hires_girl.jpg',
      cardPosterMobile: '',
      cardPosterWeb: '',
      cardContentWeb: '',
      cardType: CARD_TYPE.MODEL,
      cardCategory: 'Sculpture',
      cardAuthor: 'Pixar',
      cardTitle: 'Model (HiRes)',
      cardDescription: `Merida is an exuberant, adventurous girl who dreams of exploring the world. According to legend is not an average hero. She's just a big dreamer`,
      cardPrice: 0,
      purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
    });

    mockCardsFeed.push({
      id: 'N/A',
      modifiedDate: (new Date()).toString(),
      cardContentMobile: '/assets/images/cards/model_lowres_girl.jpg',
      cardPosterMobile: '',
      cardPosterWeb: '',
      cardContentWeb: '',
      cardType: CARD_TYPE.MODEL,
      cardCategory: 'Sculpture',
      cardAuthor: 'Pixar',
      cardTitle: 'Model (LowRes)',
      cardDescription: `Carl Fredricksen is not your average hero. He's a retired balloon to his house and flies away to the wilds of South America. of South America.`,
      cardPrice: 0,
      purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
    });

  //   mockCardsFeed.push({
  //     cardContentMobile: '/assets/images/scenesPage/mosh-full.jpg',
  //     cardType: CARD_TYPE.ALBUM,
  //     cardAuthor: 'DRAFT',
  //     cardTitle: 'Mosh',
  //     cardDescription: `Create the Sci-Fi fantasy environment of your dreams with this collection of peculiar plants, ethereal rocks and surfaces.`,
  //   });

  //   mockCardsFeed.push({
  //     cardContentMobile: '/assets/images/scenesPage/ferm-full.jpg',
  //     cardType: CARD_TYPE.MODEL,
  //     cardAuthor: 'DRAFT',
  //     cardTitle: 'Ferm',
  //     cardDescription: `According to legend, Rome was founded in 753 BC by twin sons Romulus and Remus `,
  //   });
    return mockCardsFeed
  }

  public getMockedCardsDraftsFeed(): ICard[]{
    const mockDraftsFeed: ICard[] = [];
    mockDraftsFeed.push({
      id: 'N/A',
      modifiedDate: (new Date()).toString(),
      cardContentMobile: '/assets/images/scenesPage/coliseum.jpg',
      cardPosterMobile: '',
      cardPosterWeb: '',
      cardContentWeb: '',
      cardType: CARD_TYPE.MODEL,
      cardCategory: 'Sculpture',
      cardAuthor: 'DRAFT',
      cardTitle: 'Coliseum',
      cardDescription: ``,
      cardPrice: 0,
      purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
    });

    mockDraftsFeed.push({
        id: 'N/A',
        modifiedDate: (new Date()).toString(),
        cardContentMobile: '/assets/images/homePage/carl_fredrecksen.png',
        cardPosterMobile: '',
        cardPosterWeb: '',
        cardContentWeb: '',
        cardType: CARD_TYPE.MODEL,
        cardCategory: 'Sculpture',
        cardAuthor: 'DRAFT',
        cardTitle: '',
        cardDescription: ``,
        cardPrice: 0,
        purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
    });
    return mockDraftsFeed
  }

  public getMockedCardsPublishedFeed(): ICard[]{
    const mockPublishedFeed: ICard[] = [];
    mockPublishedFeed.push({
      id: 'N/A',
      modifiedDate: (new Date()).toString(),
      cardContentMobile: '/assets/images/scenesPage/mosh-full.jpg',
      cardPosterMobile: '',
      cardPosterWeb: '',
      cardContentWeb: '',
      cardType: CARD_TYPE.MODEL,
      cardCategory: 'Sculpture',
      cardAuthor: 'DRAFT',
      cardTitle: 'Mosh',
      cardDescription: `Create the Sci-Fi fantasy environment of your dreams with this collection of peculiar plants, ethereal rocks and surfaces.`,
      cardPrice: 0,
      purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
      });
    return mockPublishedFeed
  }

  public genereteModelCardFeed(urlString: string[]): ModelCardRendered[] {
    const baseMock = {
      cardContentMobile: `N/A`,
      cardType : CARD_TYPE.MODEL,

      cardAuthor: 'Natallia Slabko',
      cardTitle: 'Model 1',
      cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
      cardPosterMobile: `https://storage-dev.3dway.io/preview/MODELFEED_HLS_10_1688_1750/02/02_poster.webp`
    }
    const result = [];

    urlString.forEach((el)=>{
      const currentMock: ModelCardRendered = JSON.parse(JSON.stringify(baseMock));
      currentMock.cardContentMobile = el;
      currentMock.cardPosterMobile = el;
      result.push(currentMock);
    })

    return result
  }

  public generateRandomCard(): ICard {
    const randomNumberSource = Math.floor(Math.random() * 9) + 2;
    const randomNumber = randomNumberSource < 10 ? '0' + randomNumberSource : randomNumberSource;

    const baseMock = {
      id: this.generateMockCollectionId(),
      modifiedDate: (new Date()).toString(),
      cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/${randomNumber}/${randomNumber}_1688_1750k.mp4`,
      cardPosterWeb: '',
      cardContentWeb: '',
      cardType : CARD_TYPE.MODEL,

      cardAuthor: 'Natallia Slabko',
      cardTitle: `Model ${randomNumber}`,
      cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
      cardPosterMobile: `https://storage-dev.3dway.io/preview/MODELFEED_HLS_10_1688_1750/${randomNumber}/${randomNumber}_poster.webp`,
      purchaseStatus: CARD_PURCHASE_STATUS.UPLOADED,
      cardPrice: undefined,
      cardCategory: 'Architecture',
    }
    return baseMock
  }

  public generateMockCollectionId(): string {
    return window.btoa(Math.random().toString().slice(0,6));
  }

  public generateRandomPrice(): number {
    return Math.floor(Math.random() * (100 - 1 + 1) + 1);
  }

  public addDataToCollectionAndSendToReview(collect: ICardCollection): ICardCollection {
    collect.collectionDescription = 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.';
    collect.collectionContentMobile = `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/LIGHNING2_conv_videoplayback3/LIGHNING2_conv_videoplayback3_1688_1750k.mp4`;
    collect.collectionPosterMobile = `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/LIGHNING2_conv_videoplayback3/LIGHNING2_conv_videoplayback3_poster.webp`;
    return collect
  }

  public generateModelCardRenderedMocksBasedOnImgUrl(imgUrl: string[], videoReplaceString?: string): ModelCardRendered[] {
    const generatedResult: ModelCardRendered[] = []
    const baseMock: ModelCardRendered = {
      id: 'N/A',
      modifiedDate: (new Date()).toString(),
      cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/02/02_1688_1750k.mp4`,
      cardType : CARD_TYPE.MODEL,
      cardCategory: 'Sculpture',
      cardAuthor: 'Natallia Slabko',
      cardTitle: 'Model ',
      cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
      cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/02/02_poster.webp`,
      cardPosterWeb: '',
      cardContentWeb: '',
      purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY,
      cardPrice: 0,
    }

    imgUrl.forEach((url, index) => {
      const cloneMock = JSON.parse(JSON.stringify(baseMock))
      cloneMock.id = this.generateMockCollectionId();
      cloneMock.cardPrice = Math.floor(Math.random() * (100 - 1 + 1) + 1);
      cloneMock.cardPosterMobile = url;
      if(videoReplaceString) cloneMock.cardContentMobile = url.replace('_poster.webp',videoReplaceString);
      cloneMock.cardTitle += (index+1).toString();
      generatedResult.push(cloneMock);
    })
    return generatedResult
  }


  public generateEmptyCollection(cards: ICard[], collName?: string): ICardCollection {
    return {
      collectionId: this.generateMockCollectionId(),
      collectionTitle: collName ? collName : 'Empty Collection',
      collectionCards: cards,
      created: new Date(Date.now()).toISOString(),
      updated: new Date(Date.now()).toISOString()
    }
  }



}
