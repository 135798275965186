import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { MaterialModule } from './material.module';


// Services
import { RedirectGuard } from './providers/redirect-guard';
import { AuthGuard } from './providers/auth.guard';
import { MockDataService } from './providers/mock-data.service';
import { SettingsService } from './providers/settings.service';


// Directives
import { SubmitIfValidDirective } from './directives/submit-if-valid.directive';
import { HttpClientModule } from '@angular/common/http';
import { CustomClassDirective } from './directives/custom-class.directive';
import { FluidLayoutService } from './providers/fluid-layout/fluid-layout.service';
import { SearchComponent } from './components/search/search.component';
import { IntersectionObserverDirective } from './directives/intersection-observer.directive';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { CardVideoComponent } from './components/card-video/card-video.component';
import { FormatImageExtensionPipe } from './pipes/format-image-extension.pipe';
import { CardThumbsUploadComponent } from './components/card-thumbs-upload/card-thumbs-upload.component';
import { UploadProgressComponent } from './components/upload-progress/upload-progress.component';
import { CardHorizontalSliderComponent } from './components/card-horizontal-slider/card-horizontal-slider.component';
import { ThumbnailGalleryComponent } from './components/thumbnail-gallery/thumbnail-gallery.component';
import { LibrarySectionSwitchComponent } from './components/library-section-switch/library-section-switch.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CallbackPipe } from './pipes/callback.pipe';
import { PriceformatPipe } from './pipes/priceformat.pipe';
import { SeeAllSwitchComponent } from './components/see-all-switch/see-all-switch.component';
import { CardCompositeSliderComponent } from './components/card-composite-slider/card-composite-slider.component';
import { SortByDatePipe } from './pipes/sort-by-date.pipe';
import { StopRenderSnackBarComponent } from './stop-render-snack-bar/stop-render-snack-bar.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { ThumbCompositeComponent } from './components/thumb-composite/thumb-composite.component';
import { CustomBreakPointsProvider } from './providers/custom-breakpoints.provider';
import { FlexLayoutModule, LayoutGapStyleBuilder } from '@angular/flex-layout';
import { CustomLayoutGapStyleBuilder } from './providers/custom-gaps-builder';
import { ResponsiveDirective } from './directives/responsive.directive';
import { FirstUseAdvComponent } from './components/first-use-adv/first-use-adv.component';
import { AuthpopupComponent } from './components/authpopup/authpopup.component';
import { RenderlimitSelectComponent } from './components/renderlimit-select/renderlimit-select.component';
import { PopupIconNotificationComponent } from './components/popup-icon-notification/popup-icon-notification.component';
import { ServiceSettingsComponent } from '../pages/service-settings/service-settings.component';
import { ConfirmationDialogComponent } from './components/dialog/confirmation-dialog.component';
import { Error404Component } from '../pages/error404/error404.component';



@NgModule({
  declarations: [
    SubmitIfValidDirective,
    IntersectionObserverDirective,
    CustomClassDirective,
    ResponsiveDirective,
    SearchComponent,
    UnderConstructionComponent,
    CardVideoComponent,
    FormatImageExtensionPipe,
    CallbackPipe,
    PriceformatPipe,
    SortByDatePipe,
    SafeUrlPipe,

    // NOT USED NOW
    UploadProgressComponent,

    Error404Component,
    ConfirmationDialogComponent,
    ServiceSettingsComponent,

    CardThumbsUploadComponent,
    CardHorizontalSliderComponent,
    ThumbnailGalleryComponent,
    LibrarySectionSwitchComponent,
    SeeAllSwitchComponent,
    CardCompositeSliderComponent,
    StopRenderSnackBarComponent,
    ThumbCompositeComponent,

    FirstUseAdvComponent,
    AuthpopupComponent,
    RenderlimitSelectComponent,
    PopupIconNotificationComponent,
  ],
  exports: [
    // Components
    SubmitIfValidDirective,
    IntersectionObserverDirective,
    CustomClassDirective,
    ResponsiveDirective,
    SearchComponent,
    UnderConstructionComponent,
    CardVideoComponent,
    CardThumbsUploadComponent,
    UploadProgressComponent,
    CardHorizontalSliderComponent,
    ThumbnailGalleryComponent,
    LibrarySectionSwitchComponent,
    SeeAllSwitchComponent,
    CardCompositeSliderComponent,
    StopRenderSnackBarComponent,
    ThumbCompositeComponent,

    FirstUseAdvComponent,
    RenderlimitSelectComponent,
    PopupIconNotificationComponent,

    FormatImageExtensionPipe,
    CallbackPipe,
    PriceformatPipe,
    SortByDatePipe,
    SafeUrlPipe,

    // Modules
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    DragDropModule,
    ScrollingModule,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    DragDropModule,
    ScrollingModule,

  ],
  providers: [
    RedirectGuard,
    AuthGuard,
    FluidLayoutService,
    MockDataService,
    SettingsService,
    CustomBreakPointsProvider,
    { provide: LayoutGapStyleBuilder, useClass: CustomLayoutGapStyleBuilder }
  ],
})
export class SharedModule { }
