<!-- <app-navbar [@navbarmodelFeedAnimation]="navbarAnimationState"  #navbarComponent [footerBarMode]="currentFooterMode" (footermodeChange)="onFootermodeChanged($event)" (navbarButtonClick)="onNavbarBtnClicked($event)"  ></app-navbar> -->
<app-webnav [@navbarmodelFeedAnimation]="navbarAnimationState"  #navbarComponent (navbarButtonClick)="onNavbarBtnClicked($event)" (webpanelActionClick)="handleWebpanelClick($event)" [sourceCollection]="collectionDetailsSource"></app-webnav>
<app-tweakerstyle *ngIf="!environment.production && settingService.showStyleTweaker"></app-tweakerstyle>
<div [@cardFeedAnimation]="currentAnimationState"  class="home-page" ngClass.lt-sm="home-page-lt-sm" ngClass.lt-md="home-page-lt-md" ngClass.gt-sm="home-page-gt-sm" ngClass.gt-lg="home-page-gt-lg">


    <app-web-models-card #collectionCardsFeed [feedIsVisible]="!isModelFeedVisible" [cardsRaw]="cards"  [noDataMessage]="cardsNoData" (cardClicked)="onCollectionClicked($event)" (cardPressed)="onModelCardPressed()" (cardVisibilityChanged)="onCardVisibilityChanged($event)" (actionBtnClicked)="onCardCollectionControlBtnClicked($event)" ></app-web-models-card>
    <app-web-collection-details #collectionDetailsComponent  *ngIf="isCollectionDetailsShown" [@feedWebCollectionDetailsAnimation]="isCollectionDetailsShown"  (@feedWebCollectionDetailsAnimation.done)="onCollectionDetailsAnimationDone($event)" (collectionDetailsThumbClick)="onCollectionDetailsThumbClick($event)"   [sourceCollection]="collectionDetailsSource" ></app-web-collection-details>
    <div [@homeOverscrollAnimation]="isModelFeedVisible" class="home-overscroll-background" *ngIf="isModelFeedVisible"></div>

    <app-web-models-card @modelFeedAnimation #modelCardsFeed class="feed-overlap model-feed" *ngIf="isModelFeedVisible" [startPosition]="modelFeedCardStartPosition"[feedIsVisible]="isModelFeedVisible" [cardsRaw]="modelFeedCards"  [feedMode]="CARDS_MODE.COMPOSITE_FEED" (cardPressed)="onModelCardPressed()" (cardClicked)="onModelCardClicked($event)"  (actionBtnClicked)="onCardCollectionControlBtnClicked($event)" (cardVisibilityChanged)="onCardVisibilityChanged($event)" ></app-web-models-card>

 </div>
