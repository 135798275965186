import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fullscreenCardAnimation, cardFeedAnimation, feedWebCollectionDetailsAnimation, modelFeedAnimation, homeOverscrollAnimation, navbarmodelFeedAnimation, inOutAnimation } from 'src/app/shared/providers/animations-list';
import { environment } from 'src/environments/environment';
import { WebCollectionDetailsComponent } from '../web-collection-details/web-collection-details.component';
import { WebModelsCardComponent } from '../web-models-card/web-models-card.component';
import { WebnavComponent } from '../webnav/webnav.component';
import { CARD_CONTROL_BTN, CARDS_MODE, Cards_Page_State, ICard, ICardAction, ICardFeedModificator, INavpanelAction, ModelCardRendered, NAVPANEL_ACTION_TYPE, NoDataMessage } from 'src/app/models/card';
import { FOOTER_MODE, NAVBAR_BTN } from 'src/app/models/menu';
import { Subscription } from 'rxjs';
import { ICardCollection } from 'src/app/models/collection';
import { CardsService } from 'src/app/shared/providers/cards.service';
import { FirebaseAuthService } from 'src/app/shared/providers/firebase-auth.service';

import { AnimationEvent } from '@angular/animations';
import { SettingsService } from 'src/app/shared/providers/settings.service';
import { UtilityFunctionsService } from 'src/app/shared/providers/utility-functions.service';
import { WebNavService } from '../webnavigation.service';
import { LOCALSTORAGE_VAR } from 'src/app/models/localstorage';



@Component({
  selector: 'app-web-shared-user-content',
  templateUrl: './web-shared-user-content.component.html',
  styleUrls: ['./web-shared-user-content.component.scss'],
  animations: [
    fullscreenCardAnimation,
    cardFeedAnimation,
    feedWebCollectionDetailsAnimation,
    modelFeedAnimation,
    homeOverscrollAnimation,
    navbarmodelFeedAnimation,
    inOutAnimation
  ],
})
export class WebSharedUserContentComponent implements OnInit, OnDestroy {
  @ViewChild('collectionCardsFeed', { static: false })
  public collectionCardsFeed: WebModelsCardComponent;

  @ViewChild('modelCardsFeed', { static: false })
  public modelCardsFeedRef: WebModelsCardComponent;

  @ViewChild('collectionDetailsComponent', { static: false })
  public collectionDetailsComponent: WebCollectionDetailsComponent;

  @ViewChild('navbarComponent', { static: false })
  public webnav: WebnavComponent;

  public currentAnimationState: string = Cards_Page_State.CARD_FEED;
  public CARDS_MODE = CARDS_MODE;
  public FOOTER_MODE = FOOTER_MODE;

  public isNoDataRecieved: boolean = false;
  public errorData: any;


  public sharedCollectionReference: ICardCollection;

  public cards: ICard[] = [];
  public collectionDetailsSource: ICardCollection;

  public backupPosterGeneratedFromFirstModel: string;
  public sharedFeedModificator: ICardFeedModificator

  public lessBtnSubscription: Subscription;
  public moreBtnSubscription: Subscription;

  public isModelFeedVisible: boolean = false;
  public modelFeedCards: ModelCardRendered[];
  public modelFeedCardStartPosition: number;

  public isCollectionDetailsShown: boolean = false;
  public isCollectionDetailsHiding: boolean = false;
  public currentCard: ICard;

  public environment: any = environment
  public isSingleModelShared: boolean;
  modelIdChangeSubscription: Subscription;

  public sharedItemId: string;
  public skipCollectionDetailsAnimation: boolean = true;

  constructor(
    private router: Router,
    private readonly route: ActivatedRoute,
    public webNavService: WebNavService,
    public firebaseAuth: FirebaseAuthService,
    public cardService: CardsService,
    public utilityService: UtilityFunctionsService,
    public settingService: SettingsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.webNavService.footerMode = FOOTER_MODE.DEFAULT;
    // http://localhost:4300/library?collectionId=6e93dc39-e00a-4dee-99c2-1c475be82683

    try {
      // const sharedId = this.route.snapshot.paramMap.get('shared_id');
      // Remove direct query parameter fetching for shared_type
      // const sharedType = this.route.snapshot.queryParamMap.get('shared_type');
      const pathSegments = this.route.snapshot.url.map(segment => segment.path);
      // if URL contains "model", then sharedType should be "model"
      const sharedType = pathSegments.includes('model') ? 'model' : 'collection'

      if(sharedType === 'model') {
        const modelIdParam = this.route.snapshot.paramMap.get('modelId');
        if (!modelIdParam) {
          throw new Error('Missing modelId in route parameters');
        }
        this.sharedItemId = modelIdParam;
      }

      if(sharedType === 'collection') {
        const collectionIdParam = this.route.snapshot.paramMap.get('collectionId');
        if (!collectionIdParam) {
          throw new Error('Missing collectionId in route parameters');
        }
        this.sharedItemId = collectionIdParam;
      }

      // https://web-dev.corp.3dway.io/model/ba15c9eb-abfa-448b-9daa-4ac41e387fb9
      // http://localhost:4300/collection/b93e7c50-42b9-49a8-a7b4-7cbf1f2617db

      const sharedData = await this.cardService.getSharedDataById(this.sharedItemId, sharedType);

      if (sharedType === 'collection') {
        this.sharedCollectionReference = sharedData as ICardCollection;
        this.sharedFeedModificator = { isSharingModelFeed: true };

        this.cards = this.cardService.convertCollectionsToCardsFeed([this.sharedCollectionReference]);
        this.collectionDetailsSource = sharedData as ICardCollection;
        this.cardService.setCurrentCard(this.cards[0]);
        this.handleMoreButtonClick(this.cards[0]);
        this.utilityService.rollbarInfo(`Shared collection opened, ${this.collectionDetailsSource.collectionAuthor}, userUid: ${this.firebaseAuth.currentUser?.uid}`)
        if (this.collectionDetailsSource.collectionAuthor === localStorage.getItem(LOCALSTORAGE_VAR.ORIGINAL_UID)) {
          console.log('User is author of shared collection, handling redirect to library: ', this.collectionDetailsSource.collectionId);
          this.router.navigate(['/library'], {
            queryParams: {
              collectionId: this.collectionDetailsSource.collectionId,
            }
          });
        }
      } else if (sharedType === 'model') {
        this.isSingleModelShared = true;
        this.modelFeedCards = [sharedData] as ModelCardRendered[];
        this.isModelFeedVisible = true;
        // DIRTY HACK TO NOT WAIT FOR AUTH:
          if(this.modelFeedCards[0]?.cardAuthor === localStorage.getItem(LOCALSTORAGE_VAR.ORIGINAL_UID)) {
            this.router.navigate(['/portfolio'], {
              queryParams: {
                modelId: this.modelFeedCards[0].id,
                darkModeRedirect: true // Add darkMode parameter
              }
            });
          }

      } else {
        console.error('Unknown shared data type');
      }
    } catch (error) {
      this.isNoDataRecieved = true;
      this.errorData = JSON.stringify(error);
      this.webNavService.footerMode = FOOTER_MODE.SHARED_PAGE_ERROR;
      console.error('Error fetching shared data:', error);
    }

    this.moreBtnSubscription = this.cardService.moreBtnSubject.subscribe((currentCard: ICard) => {
      this.handleMoreButtonClick(currentCard);
    });

    this.lessBtnSubscription = this.cardService.lessBtnSubject.subscribe(() => {
      this.handleLessButtonClick();
    });

  }

  private handleMoreButtonClick(currentCard: ModelCardRendered): void {
    this.currentCard = currentCard;
    this.collectionCardsFeed.removeLikeTouchedClass();
    this.cardService.collectionDetailsInitTimeStart = performance.now();

    this.modelFeedCards = this.collectionDetailsSource.collectionCards;
    this.cardService.setLastCollectionCard(currentCard)
    // disable animation on first appearance, then enable for subsequent toggles
    this.isCollectionDetailsShown = true;
    if (this.skipCollectionDetailsAnimation && this.currentCard?.cardPosterWeb) {
      setTimeout(() => {
        this.skipCollectionDetailsAnimation = false;
      }, 0);
    }
    this.webNavService.footerMode = FOOTER_MODE.COLLECTION_DETAILS;
  }

  private handleLessButtonClick(): void {
    // We need to show collection wrapper only when it's 3DWay collection
    console.log('handleLessButtonClick:', this.currentCard);
    if (!this.currentCard?.cardPosterWeb) {
      this.skipCollectionDetailsAnimation = true;
      console.log('handleLessButtonClick: no poster, redirecting to library');
      this.router.navigate(['/']);
      return;
    }
    if (
      this.webNavService.footerMode === FOOTER_MODE.COLLECTION_DETAILS ||
      this.webNavService.footerMode === FOOTER_MODE.COLLECTION_DETAILS_FOR_SHARED
    ) {
      this.collectionDetailsComponent.removeLikeTouchedClass();
      this.webnav.removeLikeTouchedClass();
    }
    this.isCollectionDetailsShown = false;
    this.webNavService.footerMode = FOOTER_MODE.DEFAULT;
    this.collectionCardsFeed.setFirstCardExplicitVisible();
    this.collectionCardsFeed.setActiveComponentCardToCardService();
  }


  public handleWebpanelClick(action: INavpanelAction) {
    const actionName = action.actionType
    switch (true) {
      case actionName === NAVPANEL_ACTION_TYPE.LIKE_COLLECTION:
        // DIRTY HACK: Because updates of navpanel items are very quickly and before navpanel hidden you see different variant;
         setTimeout(() => {
          this.collectionDetailsComponent.onLikeButtonClicked(this.collectionDetailsSource);
         }, 250);
        break;
    }
  }

  public onNavbarBtnClicked(navbarBtn: NAVBAR_BTN) {
    console.log('clicked ', navbarBtn)
    switch (navbarBtn) {
      case NAVBAR_BTN.HOME:
        this.router.navigate(['/home']);
        break;
      case NAVBAR_BTN.LIBRARY:
        this.router.navigate(['/']);
        break;
      case NAVBAR_BTN.TO_TOP:
        this.collectionDetailsComponent.nativeScrollToTopLight();
        break;
      case NAVBAR_BTN.SEARCH:
        console.log('not implemented')
        break;
      case NAVBAR_BTN.BUY:
        console.log('BUY BTN CLICKED');
        break;
      case NAVBAR_BTN.BACK:
          // this.currentFooterMode = FOOTER_MODE.COLLECTION_DETAILS;
          this.utilityService.notImplemented();
        break;
      case NAVBAR_BTN.SELECT_ALL:
        this.utilityService.notImplemented();
        break;
      case NAVBAR_BTN.MORE_OPTIONS:
        this.utilityService.notImplemented();
        break;
      case NAVBAR_BTN.NOT_IMPL:
        this.utilityService.notImplemented();
        break;
      default:
        break;
    }
  }

  public onCardVisibilityChanged(card: ModelCardRendered) {
    this.webNavService.footerMode = FOOTER_MODE.DEFAULT;
  }

  public onCollectionDetailsAnimationDone(event: AnimationEvent): void {
    // Skip logging if we're in the poster-less redirection case
    if ((this.skipCollectionDetailsAnimation && !this.currentCard?.cardPosterWeb)) {
      console.log('skipCollectionDetailsAnimation: ', event);
      return;
    }

    if(event.toState !== 'void') {
      this.cardService.collectionDetailsInitTimeEnd = performance.now();
      console.log('COLLECTION DETAILS INIT TIME: ', this.cardService.collectionDetailsInitTimeEnd - this.cardService.collectionDetailsInitTimeStart);
    }
    if(event.toState === 'void') {
      this.webNavService.footerMode = FOOTER_MODE.DEFAULT;
      this.collectionCardsFeed.startPlayVisibleCollectionCard();
    }
  }

  public onCollectionDetailsThumbClick(thumbNumber: number): void {
    this.modelFeedCards = this.collectionDetailsSource.collectionCards;
    console.log('onCollectionDetailsThumbClick, this.modelFeedCards: ', this.modelFeedCards)

    this.collectionCardsFeed.disposeAllVideosExceptCurrent();
    this.cardService.modelFeedInitStart = performance.now();

    this.modelFeedCardStartPosition = thumbNumber;
    this.webNavService.footerMode = FOOTER_MODE.DEFAULT;
    this.isModelFeedVisible = true;
  }

  public onNavpanelActionItemClicked(item: INavpanelAction): void {
    console.log(item.actionName);
    this.handleNavpanelAction(item);
  }

  public handleNavpanelAction(action: INavpanelAction): void {
    const actionName = action.actionType
    switch (true) {
      case actionName === NAVPANEL_ACTION_TYPE.LIKE_COLLECTION:
          this.collectionDetailsComponent.dispatchLikeClick();
        break;
      case actionName === NAVPANEL_ACTION_TYPE.SHARE_COLLECTION:
        this.collectionDetailsComponent.onShareBtnClicked(this.collectionDetailsSource);
        break;
      case actionName === NAVPANEL_ACTION_TYPE.SHARE_MODEL:
        this.modelCardsFeedRef.onShareBtnClicked(this.cardService.getCurrentCard());
        break;
      case actionName === NAVPANEL_ACTION_TYPE.UNLIKE_CARD:
        this.modelCardsFeedRef.dispatchCurrentCardLikeClick();
        break;
      default:
        break;
    }
  }

  public onCardCollectionControlBtnClicked(action: ICardAction): void {
    console.log('onCardCollectionControlBtnClicked: ', action)
    this.utilityService.triggerHaptics();
    switch (action.controlAction) {
      case CARD_CONTROL_BTN.ANONYMOUS_USER:
        this.webnav.handleUnauthorizedUserActionDetected();
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTIONS_DEFAULT:
      case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_LIKED:
        this.utilityService.notImplemented();
        break;
      case CARD_CONTROL_BTN.UNLIKE_DRAFT_CARD:
        this.modelCardsFeedRef.dispatchCurrentCardLikeClick();
        break;
      case CARD_CONTROL_BTN.CONTROL_GO_TO_DETAILS:
        if (!this.isModelFeedVisible) {
          this.currentCard = action.card;
          this.cardService.triggerMoreBtnSubject();
        }
        break;
      case CARD_CONTROL_BTN.CONTROL_BACK_BTN:
          if(this.isSingleModelShared ) {
            this.router.navigate(['/']);
            return;
          }
          this.webNavService.footerMode = FOOTER_MODE.COLLECTION_DETAILS;
          this.cardService.setCurrentCard(this.currentCard);

          this.isModelFeedVisible = false;
          // this.navbarAnimationState = 'default';
        break
      case CARD_CONTROL_BTN.SEARCH:
        this.utilityService.showImportantSnackBar('Coming soon', 250, 750);
        break;
      case CARD_CONTROL_BTN.BUY_MODEL:
        this.utilityService.showImportantSnackBar('Coming soon', 250, 750);
        break;
      default:
        break;
    }
  }


  ngOnDestroy(): void {
    this.lessBtnSubscription.unsubscribe();
    this.moreBtnSubscription.unsubscribe();
  }


}
