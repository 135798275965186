import { DtoICollection, ICardCollection } from '../../../models/collection';
import { processCardsDtoUnified, convertCardsToDtoCards } from './cards-dto-processor';

export function processCollectionDtoResponse(rawResponse: DtoICollection[]): ICardCollection[] {
  return rawResponse.map(({
    collection_id,
    collection_preview_link_app,
    collection_poster_link_app,
    collection_preview_link_web,
    collection_poster_link_web,
    author_id,
    user_id,
    owner_id,
    title,
    description,
    collection_models,
    created,
    updated
  }) => ({
    collectionId: collection_id,
    collectionContentMobile: collection_preview_link_app,
    collectionPosterMobile: collection_poster_link_app,
    collectionContentWeb: collection_preview_link_web,
    collectionPosterWeb: collection_poster_link_web,
    accessType: 'Private',
    collectionAuthor:  author_id || user_id || owner_id,
    collectionTitle: title,
    collectionDescription: description,
    created,
    updated,
    collectionCards: collection_models
      ? processCardsDtoUnified(collection_models, { parentCollectionId: collection_id })
      : []
  }));
}

export function processDraftCollectionDtoResponse(rawResponse: DtoICollection[]): ICardCollection[] {
  const collections = processCollectionDtoResponse(rawResponse);
  console.log('processDraftCollectionDtoResponse Processed: ', collections);
  return collections;
}

export function convertCollectionToDtoCollection(collection: ICardCollection): DtoICollection {
  return {
    collection_id: collection.collectionId,
    collection_preview_link_app: collection.collectionContentMobile,
    collection_poster_link_app: collection.collectionPosterMobile,
    collection_poster_link_web: collection.collectionPosterMobile,
    collection_preview_link_web: collection.collectionContentMobile,
    author_id: collection.collectionAuthor,
    user_id: collection.collectionAuthor,
    title: collection.collectionTitle,
    description: collection.collectionDescription,
    collection_models: collection.collectionCards ? convertCardsToDtoCards(collection.collectionCards) : [],
    type: 'collection',
    status: 'private',
    created: collection.created,
    updated: collection.updated
  };
}
