<div class="header-bar" *ngIf="isMobileView" [ngClass]="{'hasButtons' : hasButtonsInHeader() }" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="header-left" (click)="titleClick($event)"><h1 *ngIf="title">{{title}}</h1></div>
  <div class="header-right" fxLayout="row">
    <!-- <h3 *ngIf="title === 'Published'" class="see-all-btn" (click)="titleClick($event)">See All</h3> -->
  </div>
</div>
  <div class="slide-thumb-wrapper" [ngClass]="getMobileOrWebLayoutClass()" fxLayout="row nowrap" fxLayoutAlign="start start" [appResponsiveClass]="'slider'">

        <div class="slider-thumb-composite" [ngClass]="getMobileOrWebLayoutClass()" *ngIf="publishRejectedReviewsCards?.length > 0"  (click)="onPublishRejectedReviewThumbClicked($event)" fxLayout="column"  >
          <div class="top-row" fxFlex="0 0 calc(100% - 44px)" fxlayout="column" >
            <div class="left-part" fxFlex="1 1 67%">
              <div class="slider-thumb-img-composite img-left"
              [ngClass]="{'inReview' : publishRejectedReviewsCards[0]?.purchaseStatus === CARD_PURCHASE_STATUS.IN_REVIEW, 'rejected' : publishRejectedReviewsCards[0]?.purchaseStatus === CARD_PURCHASE_STATUS.REJECTED }"
               [ngStyle]="{'background-image': 'url(' + processcardPosterMobile(publishRejectedReviewsCards[0]?.cardPosterMobile) + ')'}"></div>
            </div>
            <div class="right-part" fxFlex="1 1 33%" fxLayout="column" fxLayoutAlign="start start">
              <div class="slider-thumb-img-composite  img-top"
              [ngClass]="{'inReview' : publishRejectedReviewsCards[1]?.purchaseStatus === CARD_PURCHASE_STATUS.IN_REVIEW, 'rejected' : publishRejectedReviewsCards[1]?.purchaseStatus === CARD_PURCHASE_STATUS.REJECTED }"
              [ngStyle]="{'background-image': 'url(' + processcardPosterMobile(publishRejectedReviewsCards[1]?.cardPosterMobile) + ')'}" fxFlex="1 1 100"></div>
              <div class="slider-thumb-img-composite  img-bottom"
              [ngClass]="{'inReview' : publishRejectedReviewsCards[2]?.purchaseStatus === CARD_PURCHASE_STATUS.IN_REVIEW, 'rejected' : publishRejectedReviewsCards[2]?.purchaseStatus === CARD_PURCHASE_STATUS.REJECTED }"
              [ngStyle]="{'background-image': 'url(' + processcardPosterMobile(publishRejectedReviewsCards[2]?.cardPosterMobile) + ')'}" fxFlex="1 1 100"></div>
            </div>
          </div>
          <div class="bottom-row first-row" fxFlex="1 1 24px" >
              <div class="bottom-title"><span>Published</span></div>
          </div>
          <div class="bottom-row" fxFlex="1 1 20px" fxlayout="column">
            <div class="bottom-count">By You</div>
          </div>
        </div>

        <ng-container *ngFor="let savedCollection of filteredSourceCollections">
          <div class="slider-thumb-single" [ngClass]="getMobileOrWebLayoutClass()"  (click)="onSavedCollectionThumbClicked(savedCollection, $event)"  fxLayout="column"  >
            <div class="top-row" fxFlex="1 1 calc(100% - 44px)" fxlayout="column" >
                <div class="slider-thumb-img-single"  [ngStyle]="{'background-image': 'url(' + processcardPosterMobile(savedCollection?.collectionPosterMobile) + ')'}"></div>
            </div>
            <div class="bottom-row first-row" fxFlex="1 1 24px" >
                <div class="bottom-title" ><span>{{savedCollection?.collectionTitle}}</span></div>
            </div>
            <div class="bottom-row" fxFlex="1 1 20px" fxlayout="column">
              <div class="bottom-count">Saved</div>
            </div>
          </div>
        </ng-container>
<!--
        <ng-container *ngFor="let savedCollection of sourceCollections">
          <div class="slider-thumb-composite"  (click)="onSavedCollectionThumbClicked(savedCollection, $event)"   fxFlex="1 1 170px" fxLayout="column"  >
            <div class="top-row" fxFlex="1 1 calc(100% - 48px)" fxlayout="column" >
              <div class="left-part" fxFlex="1 1 67%">
                <div class="slider-thumb-img-composite img-left"  [ngStyle]="{'background-image': 'url(' + processcardPosterMobile(savedCollection?.collectionPosterMobile) + ')'}"></div>
              </div>
              <div class="right-part" fxFlex="1 1 33%" fxLayout="column" fxLayoutAlign="start start">
                <div class="slider-thumb-img-composite  img-top" [ngStyle]="{'background-image': 'url(' + processcardPosterMobile(savedCollection?.collectionCards[0]?.cardPosterMobile) + ')'}" fxFlex="1 1 100"></div>
                <div class="slider-thumb-img-composite  img-bottom" [ngStyle]="{'background-image': 'url(' + processcardPosterMobile(savedCollection?.collectionCards[1]?.cardPosterMobile) + ')'}" fxFlex="1 1 100"></div>
              </div>
            </div>
            <div class="bottom-row first-row" fxFlex="1 1 24px" >
                <div class="bottom-title" ><span>{{savedCollection?.collectionTitle}}</span></div>
            </div>
            <div class="bottom-row" fxFlex="1 1 20px" fxlayout="column">
              <div class="bottom-count">Saved</div>
            </div>
          </div>
        </ng-container> -->

  </div>
