import { UtilityFunctionsService } from './utility-functions.service';
import { ICardCollection, DtoICollection, DtoICard, LikesResponseDto, ILikedUserData } from './../../models/collection';
import { MockDataService } from 'src/app/shared/providers/mock-data.service';
import { ICardLoadProgress, ICardLoadProgressProcessed, PRELOAD_DIRECTION, ModelCardRendered, ICardExtended, StripeSubscriptionData, SUBSCRIPTION_STATUS, UserSubscriptionStatusDto, StripePrices } from './../../models/card';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subject, Subscription, lastValueFrom } from 'rxjs';
import { CARD_TYPE, ICard, CARD_PURCHASE_STATUS } from 'src/app/models/card';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FirebaseAuthService } from './firebase-auth.service';
import { SettingsService } from './settings.service';
import { IappurchaseService } from './iappurchase.service';
import { RollbarService, CustomRollbar } from './rollbarlog.service';
import { User } from 'firebase/auth';
import { processCardsDtoUnified, convertCardsToDtoCards } from './processors/cards-dto-processor';
import { processDraftCollectionDtoResponse, convertCollectionToDtoCollection, processCollectionDtoResponse } from './processors/collections-dto-processor';

@Injectable({
  providedIn: 'root'
})
export class CardsService {
  public usdzHref: string = 'blank';

  private currentVisibleCard: ICard;
  private lastCollectionCard: ICard;

  public isFullscreenPreviewVisible: boolean = false;
  public currentCardSubject: Subject<ICard> = new Subject();
  public moreBtnSubject: Subject<ICard> = new Subject();
  public lessBtnSubject: Subject<any> = new Subject();
  public fullscreenPreviewSubject: Subject<boolean> = new Subject();
  public onApplicationActivationSubject: Subject<ICard> = new Subject();
  public onCardChangedInVisibleZone: Subject<ICard[]> = new Subject();
  public stripeSubscriptionPrices: StripePrices[];

  public initStartTime: number;
  public initHomePageStartTime: number;
  public homeFeedGetTime: number;
  public homeBasicInitTime: number;

  public collectionDetailsInitTimeStart: number;
  public collectionDetailsInitTimeEnd: number;

  public modelFeedInitStart: number;
  public modelFeedInitEnd: number;

  public cardProgressStats: ICardLoadProgress[] = [];

  private preloadCardsArray: HTMLVideoElement[];

  public isReversePreloadDirectionSet: boolean = false;
  private lastTriggered: Date | null = null;

  // REFACTOR LATER !!!!!

  public onCollectionFeedDataRecieved: Subject<ICardCollection[]> = new Subject();
  public firebaseAuthSubscription: Subscription;
  public currentUser: User;
  public feedCollectionCardsSource: ICardCollection[];

    // eslint-disable-next-line max-len
  constructor(
    private http: HttpClient,
    public firebaseAuth: FirebaseAuthService,
    public settingsService: SettingsService,
    public utilityService: UtilityFunctionsService,
    public mockDataService: MockDataService,
    public iappurchaseService: IappurchaseService,
    @Inject(RollbarService) private rollbar: CustomRollbar
    ) {
      this.getHrefUsdzSample().then((refUrl)=>{
        this.usdzHref = refUrl
      })
    }


public async collectionFeedInit(): Promise<void> {
  await new Promise<void>(async (resolve, reject) => {
      if(this.firebaseAuth.currentUser) {
        await this.doFullAuth(this.firebaseAuth.currentUser);
        this.setSubForStatusSubscription();
        resolve();
      } else {
        // this.firebaseAuth.firebaseSignInAnonymously();
        try {
          this.getHomeFeed({unauth: true}).then((resp) => {
            this.feedCollectionCardsSource = resp;
            this.onCollectionFeedDataRecieved.next(this.feedCollectionCardsSource);

          })
        } catch (error) {
        }
        this.firebaseAuthSubscription = this.firebaseAuth.firebaseUser.subscribe(async (usr) => {
          await this.doFullAuth(usr);
          // getting STRIPE for web payments
          if(environment.type === 'web') {
            this.getStripeSubscriptions().then((resp: StripeSubscriptionData[]) => {
              let targetPrices: StripePrices[];
              targetPrices = resp.find((el) => el.is_actual)?.prices;
              if(targetPrices === undefined) targetPrices = resp[0].prices;

              this.stripeSubscriptionPrices = targetPrices;
              console.log('getStripeSubscriptions: ', targetPrices);
            });
          }

          this.firebaseAuthSubscription.unsubscribe();
          this.setSubForStatusSubscription();
          resolve();
        });
      }
  });

  this.feedCollectionCardsSource = await this.getHomeFeed();
  this.onCollectionFeedDataRecieved.next(this.feedCollectionCardsSource);
}


public checkUserSubscriptionStatus(): SUBSCRIPTION_STATUS {
  return this.firebaseAuth.currentUser.subscriptionStatus
}

public setSubForStatusSubscription(): Promise<SUBSCRIPTION_STATUS> {
  return new Promise<SUBSCRIPTION_STATUS>((resolve, reject) => {
    console.log('****** Requesting user subscription status ')
    this.getUserSubscriptionStatusRequest().then((resp: UserSubscriptionStatusDto) => {
      this.firebaseAuth.patch3DWayUser(resp);
      resolve(resp.user_status);
    }).catch(err => {
      // Check for Safari IndexedDB error
      const isSafariIndexedDbError =
        err && err.message && err.message.includes('Error looking up record in object store by key range');
      if (isSafariIndexedDbError) {
        console.warn('Safari IndexedDB error encountered, silently handling the subscription check.');
      } else {
        this.utilityService.showErrorSnackBar(err, 700);
        setTimeout(() => {
          this.rollbar.error('Error checking subscription status: ', err);
          this.utilityService.showImportantErrorSnackBar('Something wrong with checking your subscription status, please contact support', 700);
          reject(err);
        }, 1000);
      }
    })
  });
}

public async getCollectionFeed(): Promise<ICardCollection[]> {
  if(this.feedCollectionCardsSource) {
    return new Promise((res, reject) => {
      res(JSON.parse(JSON.stringify(this.feedCollectionCardsSource)));
    });
  } else {
    return new Promise((res, reject) => {
      const sub: Subscription = this.onCollectionFeedDataRecieved.subscribe((data) => {
        res(JSON.parse(JSON.stringify(this.feedCollectionCardsSource)));
        sub.unsubscribe();
      })
    });

  }
}

public convertCollectionsToCardsFeed(col: ICardCollection[]): ICard[] {
  let cards = []
  cards = col.map(collection => {
    return {
      id: collection.collectionId,
      cardPosterMobile: collection.collectionPosterMobile,
      cardContentMobile: collection.collectionContentMobile,
      cardPosterWeb: collection.collectionPosterWeb,
      cardContentWeb: collection.collectionContentWeb,
      cardType: CARD_TYPE.COLLECTION,
      cardAuthor: collection.collectionAuthor,
      cardTitle: collection.collectionTitle,
      cardDescription: collection.collectionDescription,
      cardPrice: 0,
      purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
    }
  })
  return cards
}


private async doFullAuth(currentUser: User): Promise<void> {
  this.currentUser = currentUser;
  const authToken = await this.currentUser.getIdToken();
  // this.rollbar.info(`Full auth happened, user: ${this.currentUser.uid}`);
  await this.getSignedCookieForGCS(authToken);
  console.log(`Full auth happened, user: ${this.currentUser.uid}`)
}

  private async getSignedCookieForGCS(authToken: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + authToken,
        }),
        reportProgress: true,
        withCredentials: true
      };
      lastValueFrom(this.http.get(environment.endpoints.authUrl, httpOptions))
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
        console.error(err);
        this.firebaseAuth.clearLocalStorageData();
        this.settingsService.showToastMsg(err);
      })
    });
  }

  public getCollectionCardSourceByTitle(contentUrl: string): ICardCollection {
    const requiredCollection = this.feedCollectionCardsSource.find((cardToCheck) => {
      return cardToCheck.collectionContentMobile === contentUrl;
    })
    return requiredCollection;
  }

  public getCollectionFeedCardsById(id: string): ICard[] {
    const requiredCollection = this.feedCollectionCardsSource.find((cardToCheck) => {
      return cardToCheck.collectionId === id;
    })
    return requiredCollection.collectionCards;
  }

  public getCollectionFromHomeFeedById(id: string): ICardCollection {
    const requiredCollection = this.feedCollectionCardsSource.find((cardToCheck) => {
      return cardToCheck.collectionId === id;
    })
    return requiredCollection
  }

  public setLastCollectionCard(collectionCard: ICard): void {
    this.lastCollectionCard = collectionCard;
  }

  public getLastCollectionCard(): ICard {
    return this.lastCollectionCard;
  }

  public resetCardService(): void {
    this.currentVisibleCard = undefined;
    this.preloadCardsArray = [];
    this.cardProgressStats = [];
  }

  public setCurrentCard(card: ICard): void {
    console.log(card.cardTitle, ' ', performance.now());
    if (this.currentVisibleCard !== card) {
      if (this.currentVisibleCard?.cardType === card.cardType) {
        const changedCards: ICard[] = [];
        changedCards.push(this.currentVisibleCard);
        changedCards.push(card);
        this.onCardChangedInVisibleZone.next(changedCards);
        console.log('card changed!');
        this.userScrolledAtLeastOnce();
      } else {
        console.log('Card types do not match, not firing onCardChangedInVisibleZone');
      }
    }
    this.currentVisibleCard = card;
    this.currentCardSubject.next(this.currentVisibleCard);
  }

  public getCurrentCard(): ICard {
    return this.currentVisibleCard;
  }

  public triggerAppActivated(): void {
    this.onApplicationActivationSubject.next(this.currentVisibleCard);
    console.log('App activated!');

    const now = new Date();

    if (this.lastTriggered) {
      const timeDifference = now.getTime() - this.lastTriggered.getTime();
      const oneHour = 60 * 60 * 1000;

      if (timeDifference > oneHour) {
        const timeToWait = oneHour - timeDifference;

        this.lastTriggered = now;  // This line moved here

        setTimeout(() => {
          console.log('Triggered checking subscription status on app activation');
          this.setSubForStatusSubscription();
        }, timeToWait);

        return;
      }
    } else {
      this.lastTriggered = now;
    }
  }

  public triggerMoreBtnSubject(): void {
    this.moreBtnSubject.next(this.currentVisibleCard);
  }

  public triggerLessBtnSubject(): void {
    this.lessBtnSubject.next(this.currentVisibleCard);
  }

  public triggerShowFullscreen(): void {
    this.isFullscreenPreviewVisible = true;
    this.fullscreenPreviewSubject.next(true);
  }

  public triggerHideFullscreen(): void {
    this.isFullscreenPreviewVisible = false;
    this.fullscreenPreviewSubject.next(false);
  }

  public triggerToggleFullscreenSubject(): void {
    this.isFullscreenPreviewVisible = !this.isFullscreenPreviewVisible;
    this.fullscreenPreviewSubject.next(this.isFullscreenPreviewVisible);
  }

  public preloadNextCards(nodesToPreload: HTMLVideoElement[], currentNode: HTMLVideoElement): void {
    this.preloadCardsArray = nodesToPreload;
    if(currentNode.duration && currentNode.buffered.length > 0 && currentNode.buffered.end(0) / currentNode.duration > 0.2) this.startPreload();
  }

  public addCardsToPreload(nodesToPreload: HTMLVideoElement[]): void {
    if (nodesToPreload?.length > 0) {
      nodesToPreload.forEach((nodeToPreload) => {
        this.preloadCardsArray.push(nodeToPreload);
      })

      window.setTimeout(() => {
        this.startPreload();
      }, 100);
      console.log('** PRELOAD ARRAY LENGTH: ', this.preloadCardsArray.length, ' ** ');
    }
  }

  public onVideoLoadFinished(cardLoadProgress: ICardLoadProgress) {
    this.cardProgressStats.push(cardLoadProgress);
    this.startPreload();
  }

  public setPreloadDirection(direction: PRELOAD_DIRECTION): void {
    if(direction === PRELOAD_DIRECTION.BACKWARD) this.isReversePreloadDirectionSet = true
    else this.isReversePreloadDirectionSet = false;
  }

  public startPreload(): void {
    if (!this.preloadCardsArray) return;
    const videoArray = this.preloadCardsArray.slice();
    for (const video of videoArray) {
      if (isNaN(video.duration) || video.buffered.length === 0 || video.buffered.end(0) < video.duration * 0.95) {
        video.play().catch((err) => {
          console.warn(err);
        });
        console.log('** CARD THAT STARTED TO PRELOAD: ', video?.src, video.classList.toString());
        window.setTimeout(() => {
          if (this.currentVisibleCard?.cardContentMobile !== video.currentSrc) {
            video.pause();
          }
        }, 25);
        break;
      }
    }
  }

  public async getHrefUsdzSample(): Promise<string> {
    return this.firebaseAuth.getDownloadUrl(this.mockDataService.sampleUSDZmodelGSpathPreview)
  }

  public async setRenderLimit(userId: string, renderLimit: number): Promise<any> {
    const userToken = await this.firebaseAuth.getUserToken();
    const postBody = {
      'user_id': userId,
      'render_limit': renderLimit
    };
    let response;
    try {
      response = await lastValueFrom(this.http.post(`${environment.endpoints.upload}/set_render_limit`, postBody, this.getRequestOptions(userToken)));
      this.firebaseAuth.setRenderLimitCache(renderLimit);
      console.log('Render limit set successfully: ', response);
    } catch (error) {
      console.log('setRenderLimit ERROR FULL: ', JSON.stringify(error));
      const errMsg = error?.error?.status || error.error;
      console.log('setRenderLimit ERROR: ', errMsg);
      throw new Error(errMsg);
    }

    return response;
  }

  public async getStripeSubscriptions(): Promise<any> {
    const userToken = await this.firebaseAuth.getUserToken();
    let response
    try {
      response = await lastValueFrom(this.http.post(`${environment.endpoints.purchaseWeb}/get_stripe_subscriptions`, {}, this.getRequestOptions(userToken)));
      console.log('response get_stripe_subscriptions', response)
    } catch (error) {
      if(error?.error?.error) {
        console.log('get_stripe_subscriptions: ', error.error.error);
        return error.error.error;
      } else return JSON.stringify(error.error) as any;
    }
    return response
  }

  public async createCheckOutSession(): Promise<any> {
    const uid = await this.firebaseAuth.getUserUid();
    const userToken = await this.firebaseAuth.getUserToken();
    const postBody = {
      'prices': this.stripeSubscriptionPrices,
      'quantity':1,
      'user_id': uid,
      'paymentMethodTypes':[
         'card'
      ]
   }
   console.log('postBody: ', postBody);
    let response: any = await lastValueFrom(this.http.post(`${environment.endpoints.purchaseWeb}/create-checkout-session-embeded`, postBody, this.getRequestOptions(userToken)));
    if(response === null) response = [];
    console.log('createCheckOutSession ', response);
    return response
  }

  public async stripeCancelSubscription(): Promise<any> {
    const uid = await this.firebaseAuth.getUserUid();
    const userToken = await this.firebaseAuth.getUserToken();
    const postBody = {
      'user_id': uid,
   }
   let response;
    try {
      response = await lastValueFrom(this.http.post(`${environment.endpoints.purchaseWeb}/cancel_stripe_subscription`, postBody, this.getRequestOptions(userToken)));
      console.log('stripeCancelSubscription ', response);
      this.utilityService.showImportantSnackBar('Your subscription cancelled', 100);
      return response
    } catch (error) {
      const errMsg = error?.error?.status  || error.error;
      console.log('stripeCancelSubscription ERROR: ', errMsg);
      throw new Error(errMsg); // выбросить новую ошибку
    }

  }

  public async stripeRenewSubscription(): Promise<any> {
    const uid = await this.firebaseAuth.getUserUid();
    const userToken = await this.firebaseAuth.getUserToken();
    let response;
    try {
      response = await lastValueFrom(this.http.post(`${environment.endpoints.purchaseWeb}/renew_stripe_subscription`, { 'user_id': uid }, this.getRequestOptions(userToken)));
      console.log('stripeRenewSubscription ', response);
      this.utilityService.showImportantSnackBar('Your subscription renewed', 100);
      return response
    } catch (error) {
      const errMsg = error?.error?.status || error.error;
      console.log('stripeRenewSubscription ERROR: ', errMsg);
      throw new Error(errMsg); // выбросить новую ошибку
    }
  }


  private getRequestOptionsAnonymous(): any {
    return {
      headers: new HttpHeaders({ 'Accept': 'application/json' }),
      withCredentials: true
    };
  }

  private getRequestOptions(token: string): any {
    return {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      }),
      reportProgress: true,
      withCredentials: true,
    };
  }

  // PUBLISH !!!
  public async sendToReviewModelPostRequest(card: ICard): Promise<any> {
    const userToken = await this.firebaseAuth.getUserToken();
    const postBodyProcessCards = convertCardsToDtoCards([card]);
    const postBody = {...postBodyProcessCards[0]} as any
    postBody.model_id = card.id;

    let response
    try {
      response = await lastValueFrom(this.http.post(`${environment.endpoints.contentReview}/create_review`, postBody, this.getRequestOptions(userToken)));
      this.utilityService.showSnackBar('Models successfully sent to review', 0);
      console.log('response sendToReviewModelPostRequest', response);
    } catch (error) {
      const errMsg = error?.error?.status  || error.error;
      console.log('sendToReviewModelPostRequest ERROR: ', errMsg);
      throw new Error(errMsg); // выбросить новую ошибку
    }

    return response
  }

  public async getUserReviewPublishRejectModels(): Promise<any> {
    const userToken = await this.firebaseAuth.getUserToken();
    const uid = await this.firebaseAuth.getUserUid();
    const response: any = await lastValueFrom(this.http.post(`${environment.endpoints.contentReview}/user_reviews`, {'user_id': uid}, this.getRequestOptions(userToken)));
    const processedResponse = await this.processCardsFromDtoAsyncWithDownloadLink(response);
    console.log('getUserReviewPublishRejectModels Recieved : ', response);
    return processedResponse
}

  public async getUserReviewPublishRejectModelById(modelId: string): Promise<any> {
    const userToken = await this.firebaseAuth.getUserToken();
    const postBody = {
      'public_model_id': modelId,
    }

    const response: any = await lastValueFrom(this.http.post(`${environment.endpoints.contentReview}/get_review_by_public_model_id`, postBody, this.getRequestOptions(userToken)));
    console.log('getUserReviewPublishRejectModelById Recieved : ', response);
    return response
    }

    public async cancelReviewModelPostRequest(model?: ICard): Promise<any> {
      const userToken = await this.firebaseAuth.getUserToken();
      const uid = await this.firebaseAuth.getUserUid();
      const postBody = {'public_model_id': model.publicModelId}

      const response = await lastValueFrom(this.http.post(`${environment.endpoints.contentReview}/del_review`, postBody, this.getRequestOptions(userToken)));

      return response
    }

    public async getAllUserLikes(): Promise<ILikedUserData> {
      const uid = await this.firebaseAuth.getUserUid();
      const userToken = await this.firebaseAuth.getUserToken();

      const postBody = {'user_id': uid}

      const response: any = await lastValueFrom(this.http.post<LikesResponseDto>(`${environment.endpoints.webBackend}/likes`, postBody, this.getRequestOptions(userToken)));
      const processedResponse: ILikedUserData = {
         user_id: response.user_id ? response.user_id : '',
         liked_collections: response.collections_likes ? processCollectionDtoResponse(response.collections_likes) : [],
         liked_models: response.models_likes ? this.processCardsFromDtoHomeFeed(response.models_likes, undefined) : []
      }
      console.log('getAllUserLikes Recieved : ', response);
      return processedResponse
    }

    public async setUserLike(model: ICard | ICardCollection): Promise<any> {
      const uid = await this.firebaseAuth.getUserUid();
      const userToken = await this.firebaseAuth.getUserToken();

      let body;
      if (typeof model === 'object' && 'cardContentMobile' in model) {
        body = {
          'item_id': model.id,
          'item_type': CARD_TYPE.MODEL,
          'user_id': uid,
        }
      }
      if (typeof model === 'object' && 'collectionId' in model) {
        body = {
          'item_id': model.collectionId,
          'item_type': CARD_TYPE.COLLECTION,
          'user_id': uid,
        }
      }

      let response;
      try {
        response = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/like`, body, this.getRequestOptions(userToken)));
      } catch (error) {
        console.log('ERROR setUserLike;')
        console.log(error)
        if (error.error) this.utilityService.showErrorSnackBar(JSON.stringify(error.error), 1500)
        else this.utilityService.showErrorSnackBar(JSON.stringify(error), 1500);
        throw error;
      }
      return response
    }

    public async removeUserLike(model: ICard | ICardCollection ): Promise<any> {
      const userToken = await this.firebaseAuth.getUserToken();
      const uid = await this.firebaseAuth.getUserUid();

      let body;
      if(typeof model === 'object' && 'cardContentMobile' in model) {
        body = {
          'item_id': model.id,
          'item_type' : CARD_TYPE.MODEL,
          'user_id': uid,
        }
      }
      if(typeof model === 'object' && 'collectionId' in model) {
        body = {
          'item_id': model.collectionId,
          'item_type' : CARD_TYPE.COLLECTION,
          'user_id': uid,
        }
      }
      const response: any = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/dislike`, body,  this.getRequestOptions(userToken)));
      console.log('removeUserLike Recieved : ', response);
      return response
    }

    public async getHomeFeed(options: { unauth?: boolean } = {}): Promise<ICardCollection[]> {
      const { unauth } = options;
      let response: any;

      if(unauth) {
        response = await lastValueFrom(this.http.get(`${environment.endpoints.webBackend}/home_feed`, this.getRequestOptionsAnonymous()))
      } else {
        const userToken = await this.firebaseAuth.getUserToken();
        response = await lastValueFrom(this.http.get(`${environment.endpoints.webBackend}/home_feed`, this.getRequestOptions(userToken)));

      }
      const processedResponse = processCollectionDtoResponse(response);
      console.log('getHomeFeed Recieved : ', response);
      return processedResponse
    }

    public async getUserUploadedModels(userId: string): Promise<ICard[]> {
      const userToken = await this.firebaseAuth.getUserToken();
      let response;
      try {
        response = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/all_user_uploaded_models`, { 'user_id': userId }, this.getRequestOptions(userToken)));
        if (response === null) response = [];
      } catch (error) {
        console.log(error)
        if (error.error) this.utilityService.showErrorSnackBar(JSON.stringify(error.error), 1500)
        else this.utilityService.showErrorSnackBar(JSON.stringify(error), 1500);
      }

      const processedResponse = await this.processCardsFromDtoAsyncWithDownloadLink(response);
      processedResponse.sort((a, b) => b.modifiedDate.localeCompare(a.modifiedDate));

      console.log('getListOfUserUploadedModels Download: ', processedResponse);
      return processedResponse;
    }

    public async createCollectionPostRequest(collection?: ICardCollection): Promise<any> {
      const uid = await this.firebaseAuth.getUserUid();
      const userToken = await this.firebaseAuth.getUserToken();

      collection.collectionAuthor = uid;
      const postBody = { ...convertCollectionToDtoCollection(collection) } as any

      let response
      try {
        response = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/create_collection`, postBody, this.getRequestOptions(userToken)));
        console.log('response createCollectionPostRequest', response)
      } catch (error) {
        if (error?.error?.error) {
          console.log('createCollectionPostRequest ERROR: ', error.error.error);
          return error.error.error;
        } else return JSON.stringify(error.error) as any;
      }
      return response
    }

    public async shareDataRequest(originalId: string, dataType: 'model' | 'collection'): Promise<any> {
      try {
      const userToken = await this.firebaseAuth.getUserToken();
      const uid = await this.firebaseAuth.getUserUid();
      const postBody = {
        user_id: uid,
        'original_id': originalId,
        'type': dataType
      };
      const response: any = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/share_data`, postBody, this.getRequestOptions(userToken)));
      console.log('shareModelRequest Received: ', response);
      return response;
      } catch (error) {
      const errorCode = error?.code || error?.status || 'Unknown';
      this.utilityService.showImportantErrorSnackBar(
        `An error occurred while sharing data (Error code: ${errorCode}). Please try again later.`,
        700
      );
      throw error;
      }
    }

    public async getSharedDataById(sharedDataUid: string, type: string): Promise<ICardCollection | ICardExtended> {
      const postBody = {
        'shared_id': sharedDataUid,
        type
      };
      const response: any = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/get_shared_data_by_id`, postBody, this.getRequestOptionsAnonymous()));
      console.log('getSharedDataRequest Recieved : ', response);

      if (type === 'collection') {
        // DIRTY HACK !!!!!!!!!
        response.collection_id = response.original_id;
        // **** END OF DIRTY HACK
        return processDraftCollectionDtoResponse([response])[0];
      } else if (type === 'model') {
        console.log('dto', this.processExtendedCardsFromDto([response])[0])
        return this.processExtendedCardsFromDto([response])[0];
      } else {
        console.error('Unknown response format:', response);
        throw new Error('Unexpected response format from getSharedDataById');
      }
    }

    public async getUserDraftCollections(): Promise<any> {
      const userToken = await this.firebaseAuth.getUserToken();
      const uid = await this.firebaseAuth.getUserUid();
      const response: any = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/user_collections`, { 'user_id': uid }, this.getRequestOptions(userToken)));
      console.log('getUserCollections Recieved : ', response);
      return processDraftCollectionDtoResponse(response)
    }

    public async getModelById(modelId: string): Promise<ICard[]> {
      const userToken = await this.firebaseAuth.getUserToken();
      const postBody = {'model_id': modelId};
      const response: any = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/get_model`, postBody, this.getRequestOptions(userToken)));
      console.log('getModelById : ', this.processCardsFromDtoHomeFeed([response], ''));
      return this.processCardsFromDtoHomeFeed([response], '')
    }

    public async getCollectionByIdRequest(collecetionId: string): Promise<any> {
      const userToken = await this.firebaseAuth.getUserToken();
      const postBody = {'collection_id': collecetionId}
      const response: any = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/get_collection`, postBody, this.getRequestOptions(userToken)));
      console.log('getCollectionByIdRequest Recieved : ', response);
      return processDraftCollectionDtoResponse([response])
  }

    public async deleteUserCollection(collectionId: string): Promise<any> {
      const userToken = await this.firebaseAuth.getUserToken();
      const postBody = {'collection_id': collectionId}

      const response = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/del_collection`, postBody, this.getRequestOptions(userToken)));
      return response
    }

    public async updateCollectionPostRequest(collection?: ICardCollection): Promise<any> {
      const uid = await this.firebaseAuth.getUserUid();
      const userToken = await this.firebaseAuth.getUserToken();
      collection.collectionAuthor = uid;
      const postBody = { ...convertCollectionToDtoCollection(collection) } as any

      const response = await lastValueFrom(this.http.put(`${environment.endpoints.webBackend}/upd_collection`, postBody, this.getRequestOptions(userToken)));
      return response
    }


    public async deleteModelPostRequest(model?: ICard): Promise<any> {
      const userToken = await this.firebaseAuth.getUserToken();
      const postBody = {...convertCardsToDtoCards([model])[0]}

      const response = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/del_model`, postBody, this.getRequestOptions(userToken)));
      return response
    }

    public async updateModelPostRequest(model?: ICard): Promise<any> {
      const userToken = await this.firebaseAuth.getUserToken();
      const postBody = {...convertCardsToDtoCards([model])[0]}

      const response = await lastValueFrom(this.http.put(`${environment.endpoints.webBackend}/update_model`, postBody, this.getRequestOptions(userToken)));
      return response
    }

    public async disableUserRequest(): Promise<any> {
      const userToken = await this.firebaseAuth.getUserToken();
      const uid = await this.firebaseAuth.getUserUid();
      const requestHeader = this.getRequestOptions(userToken);
      requestHeader.headers = requestHeader.headers.append('X-Firebase-Token', userToken);

      const response = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/remove_user`, {'user_id': uid }, requestHeader));
      return response
    }

    public async stopRenderPostRequest(modelId): Promise<any> {
      const requestOptions = this.getRequestOptions(await this.firebaseAuth.getUserToken());
      const uid = await this.firebaseAuth.getUserUid();
      const postBody = { user_id: uid, model_id: modelId };
      const response = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/stop_render`, postBody, requestOptions));
      console.log('response stopRenderRequest', response);
      return response
    }

    public async activateTrialPostRequest(): Promise<any> {
      const userToken = await this.firebaseAuth.getUserToken();
      const uid = await this.firebaseAuth.getUserUid();
      const response = await lastValueFrom(this.http.post(`${environment.endpoints.upload}/activate_trial`, { user_id: uid }, this.getRequestOptions(userToken)));
      console.log('response activateTrialPostRequest', response);
      return response
    }

    public async getUserSubscriptionStatusRequest(): Promise<any> {
      const uid = await this.firebaseAuth.getUserUid();
      const userToken = await this.firebaseAuth.getUserToken();
      let response
      try {
        response = await lastValueFrom(this.http.post(`${environment.endpoints.upload}/subscription_status`, { user_id: uid},  this.getRequestOptions(userToken)));
        console.log('response getUserSubscriptionStatusRequest', response);
      } catch (error) {
        console.log('getUserSubscriptionStatusRequest ERROR FULL: ', JSON.stringify(error));
        const errMsg = error?.error?.status  || error.error;
        console.log('getUserSubscriptionStatusRequest ERROR: ', errMsg);
        throw new Error(errMsg); // выбросить новую ошибку
      }
      return response
  }

  public processCardsFromDtoHomeFeed(cardsDto: DtoICard[], parentCollectionId: string): ICardExtended[] {
    return processCardsDtoUnified(cardsDto, { parentCollectionId });
  }

  public processUserCardsFromDraftCollection(cardsDto: DtoICard[], parentCollectionId: string): ICardExtended[] {
    return processCardsDtoUnified(cardsDto, { parentCollectionId });
  }

  public processExtendedCardsFromDto(cardsDto: DtoICard[]): ICardExtended[] {
    return processCardsDtoUnified(cardsDto);
  }

  public async processCardsFromDtoAsyncWithDownloadLink(cardsDto: DtoICard[]): Promise<ICardExtended[]> {
    const cards = processCardsDtoUnified(cardsDto, { resolveWithDownloadLink: true });
    return Promise.all(
      cards.map(async card => ({
        ...card,
        cardModelLink: card.cardModelLink ? await this.firebaseAuth.getDownloadUrlRef(card.cardModelLink) : card.cardModelLink,
      }))
    );
  }

  public userKnowsHowToScrollFeed(): boolean {
    return this.firebaseAuth.getFirstTimeScrolledAnyFeed();
  }

  private userScrolledAtLeastOnce(): void {
    this.firebaseAuth.setFirstTimeScrolledAnyFeed(true);
  }

}


