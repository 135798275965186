import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CardBuyPanelComponent } from './shared/components/card-buy-panel/card-buy-panel.component';
import { CardPublishPanelComponent } from './shared/components/card-publish-panel/card-publish-panel.component';
import { CardSelectPanelComponent } from './shared/components/card-select-panel/card-select-panel.component';
import { CollectionSelectPanelComponent } from './shared/components/collection-select-panel/collection-select-panel.component';
import { EditCollaboratorsComponent } from './shared/components/edit-collaborators/edit-collaborators.component';
import { MobileRenderLimitComponent } from './shared/components/mobile-render-limit/mobile-render-limit.component';
import { PriceSelectPanelComponent } from './shared/components/price-select-panel/price-select-panel.component';
import { ProfileMainComponent } from './shared/components/profile-main/profile-main.component';
import { SplashScreenComponent } from './shared/components/splash-screen/splash-screen.component';
import { MaterialModule } from './shared/material.module';
import { SharedModule } from './shared/shared.module';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { NavpanelComponent } from './shared/components/navpanel/navpanel.component';
import { ModelsCardComponent } from './shared/components/models-card/models-card.component';
import { CollectionDetailsComponent } from './shared/components/collection-details/collection-details.component';
import { NameChangeDialogueComponent } from './shared/components/name-change-dialogue/name-change-dialogue.component';
import { CreateNewCollectionComponent } from './shared/components/create-new-collection/create-new-collection.component';
import { SubscriptionSplashComponent } from './shared/components/subscription-splash/subscription-splash.component';
import { RejectedScreenComponent } from './shared/components/rejected-screen/rejected-screen.component';
import { EditCollectionComponent } from './pages/edit-collection/edit-collection.component';
import { LibraryComponent } from './pages/library/library.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { HomeComponent } from './pages/home/home.component';
import { MobileSharedUserContentComponent } from './mobile/mobile-shared-user-content/mobile-shared-user-content.component';

@NgModule({
  declarations: [
    HomeComponent,
    PortfolioComponent,
    LibraryComponent,
    EditCollectionComponent,

    NavbarComponent,
    NavpanelComponent,
    ModelsCardComponent,
    CollectionDetailsComponent,

    NameChangeDialogueComponent,
    CreateNewCollectionComponent,
    SubscriptionSplashComponent,
    RejectedScreenComponent,

    SplashScreenComponent,
    ProfileMainComponent,
    CardSelectPanelComponent,
    CardBuyPanelComponent,
    CardPublishPanelComponent,
    CollectionSelectPanelComponent,
    PriceSelectPanelComponent,
    EditCollaboratorsComponent,
    MobileRenderLimitComponent,
    MobileSharedUserContentComponent
  ],
  exports: [
    NavbarComponent,
    NavpanelComponent,
    ModelsCardComponent,
    CollectionDetailsComponent,

    NameChangeDialogueComponent,
    CreateNewCollectionComponent,
    SubscriptionSplashComponent,
    RejectedScreenComponent,


    SplashScreenComponent,
    ProfileMainComponent,
    CardSelectPanelComponent,
    CardBuyPanelComponent,
    CardPublishPanelComponent,
    CollectionSelectPanelComponent,
    PriceSelectPanelComponent,
    EditCollaboratorsComponent,
    MobileRenderLimitComponent
  ],
  imports: [
    SharedModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MaterialModule,
  ]
})
export class MobileModule { }
