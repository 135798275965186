import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'formatImageExtension'
})
export class FormatImageExtensionPipe implements PipeTransform {

  transform(posterSource: string, devicePlatform: string ,...args: unknown[]): unknown {
    const base64BlackDot = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwAB/6Gk9QAAAABJRU5ErkJggg==';
    if(posterSource === undefined || posterSource === null) return base64BlackDot;
    if(devicePlatform === 'ios') return posterSource;
    if(environment.type === 'web') return posterSource;
    return posterSource.replace('.webp','.jpeg');
  }

}
