import { UtilityFunctionsService } from 'src/app/shared/providers/utility-functions.service';
import { UploadFileService } from 'src/app/shared/providers/upload-file.service';
import { UserDataService } from 'src/app/shared/providers/user-data.service';
import { MatSnackBarContainer } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CardsService } from 'src/app/shared/providers/cards.service';
import { ModelCardService } from './../../models/card';
import { MockDataService } from 'src/app/shared/providers/mock-data.service';
import { SettingsService } from './../../shared/providers/settings.service';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms'
import { FirebaseAuthService } from 'src/app/shared/providers/firebase-auth.service';
import { HttpClient } from '@angular/common/http';
import { LOG_LEVEL_OPTION } from 'src/app/models/common';
import { IappurchaseService } from 'src/app/shared/providers/iappurchase.service';
import 'cordova-plugin-purchase/www/store.d'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogData, ConfirmationDialogComponent } from 'src/app/shared/components/dialog/confirmation-dialog.component';
import { DOCUMENT } from '@angular/common';
import { FOOTER_MODE } from 'src/app/models/menu';

@Component({
  selector: 'app-service-settings',
  templateUrl: './service-settings.component.html',
  styleUrls: ['./service-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ServiceSettingsComponent implements OnInit {


  LOG_LEVEL_OPTION = LOG_LEVEL_OPTION;
  public currentFooterMode: FOOTER_MODE = FOOTER_MODE.SERVICE

  public originalUid: string;
  public customUid: string;
  public newCustomUid: string;

  public mockCardsForm: ModelCardService[];

  public error: string;
  public approvedProdcuts: any[] = [];

  // eslint-disable-next-line max-len
  constructor(private formBuilder: UntypedFormBuilder, private http: HttpClient, public firebaseAuth: FirebaseAuthService,
    public settingsService: SettingsService, public cardService: CardsService,  private mockService: MockDataService,
    public utilityService: UtilityFunctionsService,
    public iapPurchaseService: IappurchaseService,
    public userDataService: UserDataService, public router: Router, public uploadFileService: UploadFileService,
    @Inject(DOCUMENT) private document: any, public dialog: MatDialog,
    ) { }

  ngOnInit() {

    this.firebaseAuth.clearLocalStorageData();

    this.firebaseAuth.getUserUid().then(data => {
      this.originalUid = this.firebaseAuth.currentUser.uid
    });
    this.firebaseAuth.getUserUid().then(uid => {
      this.customUid = uid;
    });

    this.mockCardsForm = this.mockService.mockCardsDiscovery;
    console.log(this.mockService.mockCardsDiscovery)
  }


  async getToken() {
    console.log(await this.firebaseAuth.currentUser.getIdToken())
  }

  applyUid() {
    this.customUid = this.newCustomUid;
    this.settingsService.setCustomUidInLocalstorage(this.newCustomUid);
    this.utilityService.showSnackBar('UID changed to: ' + this.newCustomUid + ' Dont forget to relaunch app!', 500);
  }


  public navigateToFeed(): void {
    this.router.navigateByUrl('/home');
  }


  public patchSnackBarAnimation(): void {
        // DIRTY-WORKAROUND TO PATCH ANGULAR ANIMATION;
        const c: any = MatSnackBarContainer.ɵcmp as any;
        const d = c.data.animation[0];
        const e = c.data.animation[0].definitions[0].styles.styles.transform = `scale(${this.settingsService.snackBarAppearScale})`;
        c.data.animation[0].definitions[3].animation.timings = `${this.settingsService.snackBarHideTiming}ms cubic-bezier(0.4, 0.0, 1, 1)`;
        c.data.animation[0].definitions[2].animation.timings = `${this.settingsService.snackBarAppearTiming}ms cubic-bezier(0, 0, 0.2, 1)`;

  }

  clearUploads(): void {
    this.firebaseAuth.deleteDocumentsWithLoadStatusNotFinished().then(()=> {
      this.uploadFileService.uploadModels = [];
    })
  }

  restorePurchases(): void {
    this.iapPurchaseService.restorePurchases();
  }

  manageBilling(): void {
    this.iapPurchaseService.manageBilling();
  }

  manageSubscriptions(): void {
    this.iapPurchaseService.manageSubscriptions();
  }

  private deleteAllModels(): void {
    this.userDataService.uploadedCards.forEach((el) => {
      this.cardService.deleteModelPostRequest(el);
    });
  }

  public openDeleteCardConfirmationDialog() {
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        dialogTitle: 'Are you sure?',
        dialogText: `Do you want to delete all models? `,
        confirmBtntext: 'Yes',
        declineBtnText: 'Back'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
          this.deleteAllModels()
      }
    });
  }

  clearPurchaseQueue(): void {
    // this.store.autoFinishTransactions = true;
    // this.store.refresh();
  }

  getAllProducts(): void {
    // this.store.refresh();
    // const ev = this.store.when('product').updated((p: IAPProduct) => {
    //   console.log(p.id + ' ' + p.title + ' ' + p.state);
    //   console.log(p);
    //   if(p.state === 'approved') {
    //     p.finish();
    //     this.approvedProdcuts.push(JSON.stringify(p));
    //   }
    // });
  }

}

