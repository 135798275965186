import { UtilityFunctionsService } from './../../shared/providers/utility-functions.service';
import { UserDataService } from 'src/app/shared/providers/user-data.service';
import { CollectionDetailsComponent } from './../../shared/components/collection-details/collection-details.component';
import { feedCollectionDetailsAnimation, fullscreenPanelAnimation, homeOverscrollAnimation, inOutAnimation, modelFeedAnimation, navbarDelayAnimation, navbarmodelFeedAnimation, navpanelAnimation } from './../../shared/providers/animations-list';
import { SettingsService } from './../../shared/providers/settings.service';
import { ModelCardRendered, CARDS_MODE, INavpanelAction, NAVPANEL_ACTION_TYPE, ICardAction, CARD_CONTROL_BTN, COLLECTION_CONTROL_BTN, Cards_Page_State, ICardFeedModificator } from './../../models/card';
import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, Inject } from '@angular/core';
import { ICard } from 'src/app/models/card';
import { ModelsCardComponent } from 'src/app/shared/components/models-card/models-card.component';
import { ActivatedRoute, Router } from '@angular/router';
import { pageFadingAnimation, fullscreenCardAnimation, cardFeedAnimation, cardFeedNavbarAnimation } from 'src/app/shared/providers/animations-list';
import { MockDataService } from 'src/app/shared/providers/mock-data.service';
import { NavbarComponent } from 'src/app/shared/components/navbar/navbar.component';
import { AnimationEvent } from '@angular/animations';
import { Subscription } from 'rxjs';
import { CardsService } from 'src/app/shared/providers/cards.service';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { ICardCollection } from 'src/app/models/collection';
import { IAdditionalFooterData } from 'src/app/models/common';
import { FirebaseAuthService } from 'src/app/shared/providers/firebase-auth.service';
import { CustomRollbar, RollbarService } from 'src/app/shared/providers/rollbarlog.service';
import { FOOTER_MODE, NAVBAR_BTN } from 'src/app/models/menu';
import { LOCALSTORAGE_VAR } from 'src/app/models/localstorage';


@Component({
  selector: 'app-mobile-shared-user-content',
  templateUrl: './mobile-shared-user-content.component.html',
  styleUrls: ['./mobile-shared-user-content.component.scss'],
  animations: [
    pageFadingAnimation,
    fullscreenCardAnimation,
    cardFeedAnimation,
    cardFeedNavbarAnimation,
    feedCollectionDetailsAnimation,
    modelFeedAnimation,
    navbarDelayAnimation,
    homeOverscrollAnimation,
    navbarmodelFeedAnimation,
    fullscreenPanelAnimation,
    navpanelAnimation,
    inOutAnimation
],
})
export class MobileSharedUserContentComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('collectionCardsFeed', { static: false })
  public collectionCardsFeed: ModelsCardComponent;

  @ViewChild('modelCardsFeed', { static: false })
  public modelCardsFeedRef: ModelsCardComponent;

  @ViewChild('collectionDetailsComponent', { static: false })
  public collectionDetailsComponent: CollectionDetailsComponent;

  @ViewChild('navbarComponent', { static: true })
  public navbarComponent: NavbarComponent;

  public CARDS_MODE = CARDS_MODE;

  public cards: ICard[] = [];

  public collectionDetailsSource: ICardCollection;


  public detailsSource: ModelCardRendered;
  public lessBtnSubscription: Subscription;
  public moreBtnSubscription: Subscription;

  public modelFeedCardStartPosition: number;

  public isCardSelectShown: boolean = false;
  public isSearchActive: boolean = false;
  // public isCardSelectShown: boolean = true;

  public isModelFeedVisible: boolean = false;

  public currentAnimationState: string = Cards_Page_State.CARD_FEED;
  public navbarAnimationState: string = 'default';

  public modelFeedCards: ModelCardRendered[];
  public modelCardsMockSource: ModelCardRendered[] = JSON.parse(JSON.stringify(this.mockDataService.modelCardsMockSourceShort));

  public additionaNavData: IAdditionalFooterData = {priceData: undefined};
  public FOOTER_MODE = FOOTER_MODE;
  public currentFooterMode: FOOTER_MODE = FOOTER_MODE.DEFAULT;

  public isCollectionDetailsShown: boolean = false;
  public isCollectionDetailsHiding: boolean = false;
  public currentCard: ICard;

  public homeNavpanelItems: INavpanelAction[] = [];

  public isNavpanelShown: boolean;
  public navpanelAnimationState: string;
  public isNavpanelHidden: boolean;
  public firebaseUserSubscription: Subscription;

  public isNoDataRecieved: boolean;
  public errorData: string;
  public itemId: string;
  public sharedCollectionReference: ICardCollection;
  public sharedFeedModificator: ICardFeedModificator;
  public isSingleModelShared: boolean;
  public skipCollectionDetailsAnimation: boolean = true;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private mockDataService: MockDataService,
    public settingsService: SettingsService,
    public cardService: CardsService,
    public userDataService: UserDataService,
    public firebaseAuth: FirebaseAuthService,
    @Inject(RollbarService) private rollbar: CustomRollbar,
    public utilityService: UtilityFunctionsService
    ) {


     }

     async ngOnInit(): Promise<void> {
      this.currentFooterMode = FOOTER_MODE.DEFAULT;

      try {
        const pathSegments = this.route.snapshot.url.map(segment => segment.path);
        const sharedType = pathSegments.includes('model') ? 'model' : 'collection'

        if(sharedType === 'model') {
          const modelIdParam = this.route.snapshot.paramMap.get('modelId');
          if (!modelIdParam) {
            throw new Error('Missing modelId in route parameters');
          }
          this.itemId = modelIdParam;
        }

        if(sharedType === 'collection') {
          const collectionIdParam = this.route.snapshot.paramMap.get('collectionId');
          if (!collectionIdParam) {
            throw new Error('Missing collectionId in route parameters');
          }
          this.itemId = collectionIdParam;
        }

        // https://web-dev.corp.3dway.io/model/ba15c9eb-abfa-448b-9daa-4ac41e387fb9
        // http://localhost:4300/collection/b93e7c50-42b9-49a8-a7b4-7cbf1f2617db

        const sharedData = await this.cardService.getSharedDataById(this.itemId, sharedType);

        if (sharedType === 'collection') {
          this.sharedCollectionReference = sharedData as ICardCollection;
          this.sharedFeedModificator = { isSharingModelFeed: true };

          this.cards = this.cardService.convertCollectionsToCardsFeed([this.sharedCollectionReference]);
          this.collectionDetailsSource = sharedData as ICardCollection;
          this.cardService.setCurrentCard(this.cards[0]);
          this.handleMoreBtnAction(this.cards[0]);
          if (this.collectionDetailsSource.collectionAuthor === localStorage.getItem(LOCALSTORAGE_VAR.ORIGINAL_UID)) {
            console.log('User is author of shared collection, handling redirect to library: ', this.collectionDetailsSource.collectionId);
            this.router.navigate(['/library'], {
              queryParams: {
                collectionId: this.collectionDetailsSource.collectionId,
              }
            });
          }

        } else if (sharedType === 'model') {
          this.isSingleModelShared = true;
          this.modelFeedCards = [sharedData] as ModelCardRendered[];
          this.isModelFeedVisible = true;
          // DIRTY HACK TO NOT WAIT FOR AUTH:
            if(this.modelFeedCards[0]?.cardAuthor === localStorage.getItem(LOCALSTORAGE_VAR.ORIGINAL_UID)) {
              this.router.navigate(['/portfolio'], {
                queryParams: {
                  modelId: this.modelFeedCards[0].id,
                  darkModeRedirect: true
                }
              });
            }
        } else {
          console.error('Unknown shared data type');
        }
      } catch (error) {
        this.isNoDataRecieved = true;
        this.errorData = JSON.stringify(error);
        this.currentFooterMode = FOOTER_MODE.SHARED_PAGE_ERROR;
        console.error('Error fetching shared data:', error);
      }

      this.moreBtnSubscription = this.cardService.moreBtnSubject.subscribe((currentCard: ICard) => {
        this.handleMoreBtnAction(currentCard);
      });

      this.lessBtnSubscription = this.cardService.lessBtnSubject.subscribe(() => {
        this.handleLessBtnAction();
      });

    }

  ngAfterViewInit(): void {
  }

  private handleMoreBtnAction(currentCard: ICard): void {
    this.currentCard = currentCard;
    this.collectionCardsFeed.removeLikeTouchedClass();
    this.cardService.collectionDetailsInitTimeStart = performance.now();
    this.detailsSource = currentCard;
    this.cardService.setLastCollectionCard(currentCard)
    this.modelFeedCards = this.collectionDetailsSource.collectionCards;
    this.isCollectionDetailsShown = true;

    // Add check for poster web and handle skip animation
    if (this.skipCollectionDetailsAnimation && currentCard?.cardPosterWeb) {
      setTimeout(() => {
        this.skipCollectionDetailsAnimation = false;
      }, 0);
    }

    this.currentFooterMode = FOOTER_MODE.COLLECTION_DETAILS;
  }

  private handleLessBtnAction(): void {
    console.log('LessBtnSubscription handle:', this.currentCard);
    if (!this.currentCard?.cardPosterWeb) {
      this.skipCollectionDetailsAnimation = true;
      console.log('handleLessBtnAction: no poster, redirecting to library');
      this.router.navigate(['/']);
      return;
    }

    if(this.currentFooterMode === FOOTER_MODE.COLLECTION_DETAILS || this.currentFooterMode === FOOTER_MODE.COLLECTION_DETAILS_FOR_SHARED) {
      this.collectionDetailsComponent.removeLikeTouchedClass();
    }
    this.isCollectionDetailsShown = false;
    this.currentFooterMode = FOOTER_MODE.COLLECTION_DETAILS_TRANSITION_TO_HOME;
    this.navbarAnimationState = 'default';
    this.navbarAnimationState = 'transitionToFeed';
    this.collectionCardsFeed.setFirstCardExplicitVisible();
    this.collectionCardsFeed.setActiveComponentCardToCardService();
  }

  public onCollectionClicked() {
    this.currentFooterMode = this.currentFooterMode === FOOTER_MODE.DEFAULT || this.currentFooterMode === FOOTER_MODE.SHOW_BUY_BTN ? FOOTER_MODE.CARD_FULLSCREEN : FOOTER_MODE.DEFAULT;
  }

  public onModelCardClicked(card: ModelCardRendered) {
    this.currentFooterMode = this.currentFooterMode === FOOTER_MODE.DEFAULT || this.currentFooterMode === FOOTER_MODE.SHOW_BUY_BTN ? FOOTER_MODE.CARD_FULLSCREEN : FOOTER_MODE.DEFAULT;
  }

  public onModelCardPressed() {
    this.currentFooterMode = FOOTER_MODE.CARD_FULLSCREEN;
  }

  public onFootermodeChanged(mode: FOOTER_MODE) {
    this.currentFooterMode = mode;
  }

  public onNavbarBtnClicked(navbarBtn: NAVBAR_BTN) {
    console.log('clicked ', navbarBtn)
    switch (navbarBtn) {
      case NAVBAR_BTN.TO_TOP:
        this.collectionDetailsComponent.nativeScrollToTopLight();
        break;
      case NAVBAR_BTN.BACK:
        if(this.isSearchActive) {
          this.currentFooterMode = FOOTER_MODE.DEFAULT;
          this.isSearchActive = false;
        } else {
          this.currentFooterMode = FOOTER_MODE.COLLECTION_DETAILS;
          this.isCardSelectShown = false;
        }
        break;
      case NAVBAR_BTN.SELECT_ALL:
        this.utilityService.notImplemented();
        break;
      case NAVBAR_BTN.MORE_OPTIONS:
        if(this.userDataService.isCollectionExitsInSaved(this.collectionDetailsSource)) {
          this.homeNavpanelItems = [
            {actionName: 'Remove Collection', actionType: NAVPANEL_ACTION_TYPE.LIKE_COLLECTION },
            {actionName: 'Share Collection', actionType: NAVPANEL_ACTION_TYPE.SHARE_COLLECTION },
          ]
        } else {
          this.homeNavpanelItems = [
            {actionName: 'Add to Library', actionType: NAVPANEL_ACTION_TYPE.LIKE_COLLECTION },
            {actionName: 'Share Collection', actionType: NAVPANEL_ACTION_TYPE.SHARE_COLLECTION },
          ]
        }

        this.isNavpanelShown = true;
        break;
      case NAVBAR_BTN.NOT_IMPL:
        this.utilityService.notImplemented();
        break;
      default:
        break;
    }
  }

  public onCardVisibilityChanged(card: ModelCardRendered) {
    this.currentFooterMode = FOOTER_MODE.DEFAULT;
  }

  public onNavbarAnimationDone(event: AnimationEvent): void {
    console.log('onNavbarAnimationDone start: ', event?.toState);
  }

  public onCollectionDetailsAnimationStart(event: AnimationEvent): void {
    console.log('onCollectionDetailsAnimationStart start');

    if(event.toState === 'void') {
      this.currentFooterMode = FOOTER_MODE.COLLECTION_DETAILS_TRANSITION_TO_HOME;
    }
  }

  public onCollectionDetailsAnimationDone(event: AnimationEvent): void {
    console.log('onCollectionDetailsAnimationStart done');

    // Skip logging if we're in the poster-less redirection case
    if ((this.skipCollectionDetailsAnimation && !this.currentCard?.cardPosterWeb)) {
      return;
    }

    if(event.toState !== 'void') {
      this.cardService.collectionDetailsInitTimeEnd = performance.now();
      console.log('COLLECTION DETAILS INIT TIME: ', this.cardService.collectionDetailsInitTimeEnd - this.cardService.collectionDetailsInitTimeStart);
    }

    if(event.toState === 'void') {
      this.currentFooterMode = FOOTER_MODE.DEFAULT;
      this.collectionCardsFeed.startPlayVisibleCollectionCard();
      this.navbarAnimationState = 'default';
    }
  }

  public onCollectionDetailsControlButtonClicked(buttonName: COLLECTION_CONTROL_BTN): void {
    switch (buttonName) {
      case COLLECTION_CONTROL_BTN.ANONYMOUS_USER:
        this.navbarComponent.handleUnauthorizedUserActionDetected();
        break;
      default:
        break;
    }
  }

  public onCollectionDetailsThumbClick(thumbNumber: number): void {

    this.modelFeedCards = this.collectionDetailsSource.collectionCards;
    console.log('this.modelFeedCards: ', this.modelFeedCards)

    if(this.settingsService.devicePlatform === 'ios') {
      Haptics.impact({
        style: ImpactStyle.Light
      });
    }
    this.collectionCardsFeed.disposeAllVideosExceptCurrent();

    this.navbarAnimationState = 'modelFeed';
    this.modelFeedCardStartPosition = thumbNumber;
    this.currentFooterMode = FOOTER_MODE.DEFAULT;
    this.isModelFeedVisible = true;

    console.log('NAVBAR ANIM STATE:', this.navbarAnimationState)
  }

  public onNavpanelActionItemClicked(item: INavpanelAction): void {
    console.log(item.actionName);
    this.handleNavpanelAction(item);
    this.isNavpanelShown = false;
  }

  public handleNavpanelAction(action: INavpanelAction): void {
    const actionName = action.actionType
    switch (true) {
      case actionName === NAVPANEL_ACTION_TYPE.LIKE_COLLECTION:
          this.collectionDetailsComponent.dispatchLikeClick();
        break;
      case actionName === NAVPANEL_ACTION_TYPE.SHARE_COLLECTION:
        this.collectionDetailsComponent.onShareBtnClicked(this.collectionDetailsSource);
        break;
      case actionName === NAVPANEL_ACTION_TYPE.SHARE_MODEL:
        this.modelCardsFeedRef.onShareBtnClicked(this.cardService.getCurrentCard());
        break;
      case actionName === NAVPANEL_ACTION_TYPE.UNLIKE_CARD:
        this.modelCardsFeedRef.dispatchCurrentCardLikeClick();
        break;
      default:
        break;
    }
  }

  public onHideNavpanelEventEmitted(): void {
    this.isNavpanelShown = false;
  }

  public onNavpanelDestroy(): void {
    this.navpanelAnimationState = 'visible';
    this.isNavpanelHidden = false;
  }

  public onNavpanelAnimationDone(event: AnimationEvent): void {
    console.log('animation done');
    console.log(event.phaseName);
    if(event.toState !== 'void') {
      if(this.navpanelAnimationState === 'hidden') this.isNavpanelHidden = true;
    }
  }

  public onCardCollectionControlBtnClicked(action: ICardAction): void {
    console.log('onCardCollectionControlBtnClicked: ', action)
    this.utilityService.triggerHaptics();
    switch (action.controlAction) {
      case CARD_CONTROL_BTN.ANONYMOUS_USER:
        this.navbarComponent.handleUnauthorizedUserActionDetected();
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTIONS_DEFAULT:
      case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_LIKED:
        if(this.userDataService.isCardExitsInSaved(action.card)) {
          this.homeNavpanelItems = [
            {actionName: 'Remove from Library', actionType: NAVPANEL_ACTION_TYPE.UNLIKE_CARD },
            {actionName: 'Share Model', actionType: NAVPANEL_ACTION_TYPE.SHARE_MODEL },
          ]
        } else {
          this.homeNavpanelItems = [
            {actionName: 'Add to Library', actionType: NAVPANEL_ACTION_TYPE.UNLIKE_CARD },
            {actionName: 'Share Model', actionType: NAVPANEL_ACTION_TYPE.SHARE_MODEL },
          ]
        }
        this.isNavpanelShown = true;
        break;
      case CARD_CONTROL_BTN.CONTROL_GO_TO_DETAILS:
        if (!this.isModelFeedVisible) {
          this.detailsSource = action.card;
          this.currentCard = action.card;
          this.cardService.triggerMoreBtnSubject();
        } else {
          this.currentFooterMode = FOOTER_MODE.COLLECTION_DETAILS;
          this.isModelFeedVisible = false;
          this.navbarAnimationState = 'default';
        }
        break;
      case CARD_CONTROL_BTN.CONTROL_BACK_BTN:
          if(this.isSingleModelShared ) {
            this.router.navigate(['/']);
            return;
          }
          this.currentFooterMode = FOOTER_MODE.COLLECTION_DETAILS;
          this.isModelFeedVisible = false;
          this.navbarAnimationState = 'default';
        break
      case CARD_CONTROL_BTN.SEARCH:
        this.utilityService.showImportantSnackBar('Coming soon', 250, 750);
        break;
      default:
        break;
    }
  }

  public isNoPurchasePossible(collection: ICardCollection): boolean {
    if(collection?.collectionCards?.length > 0) {
      const isAnyCardPossibleToPurchase = collection.collectionCards.some(card => card.cardPrice && card.cardPrice > 0);
      // const isAny = collection.collectionCards.every(card => card.cardPrice && card.cardPrice > 0);
      return !isAnyCardPossibleToPurchase;
    } else return true;
  }

  public onCancelSearchClicked(): void {
    this.isSearchActive = false;
    this.currentFooterMode = FOOTER_MODE.DEFAULT;
  }

  ngOnDestroy(): void {
    if(this.firebaseUserSubscription) this.firebaseUserSubscription.unsubscribe();
    this.lessBtnSubscription.unsubscribe();
    this.moreBtnSubscription.unsubscribe();
  }

}
