// cards-dto-processor.ts

import { ICardExtended, CARD_TYPE, CARD_PURCHASE_STATUS, ICard } from '../../../models/card';
import { DtoICard } from '../../../models/collection';

export interface CardProcessingOptions {
  parentCollectionId?: string;
  resolveWithDownloadLink?: boolean;
}

export function processCardsDtoUnified(cardsDto: DtoICard[], options: CardProcessingOptions = {}): ICardExtended[] {
  // Гарантируем, что это массив
  if (!Array.isArray(cardsDto)) {
    // либо выбрасываем ошибку, либо конвертируем в [cardsDto]
    // throw new Error('cardsDto is not an array');
    cardsDto = [cardsDto] as DtoICard[];
  }

  return cardsDto.map(dto => processCardDtoUnified(dto, options));
}

export function processCardDtoUnified(dtoCard: DtoICard, options: CardProcessingOptions): ICardExtended {
  const { parentCollectionId } = options;

  const baseCard: ICardExtended = {
    id: dtoCard.model_id || dtoCard.original_id,
    parentCollectionId,
    publicModelId: dtoCard.public_model_id,
    modifiedDate: dtoCard.last_update || dtoCard.creation_date || new Date().toISOString(),
    cardContentMobile: dtoCard.preview_link_app || '',
    cardPosterMobile: dtoCard.poster_link_app || '',
    cardPosterWeb: dtoCard.poster_link_web || '',
    cardContentWeb: dtoCard.preview_link_web || '',
    cardCategory: dtoCard.card_category || '',
    cardType: dtoCard.type === 'collection' ? CARD_TYPE.COLLECTION : CARD_TYPE.MODEL,
    // USING owener_id should be depricated soon !!!
    cardAuthor: dtoCard.owner_id || dtoCard.user_id,
    cardTitle: dtoCard.title || '',
    cardDescription: dtoCard.description || '',
    cardPrice: processCardPrice(dtoCard.price),
    purchaseStatus: dtoCard.review_status ? processCardStatus(dtoCard.review_status ) : setCustomStatus(dtoCard.status, dtoCard.user_id),
    reviewDetails: dtoCard.review_details,
  };

  if(options?.resolveWithDownloadLink) baseCard.cardModelLink = dtoCard.download_link || '';

  return baseCard;
}

export function processCardStatus(status?: string): CARD_PURCHASE_STATUS {
  switch (status) {
    case 'purchased': return CARD_PURCHASE_STATUS.PURCHASED;
    case 'uploaded':  return CARD_PURCHASE_STATUS.UPLOADED;
    case 'liked':     return CARD_PURCHASE_STATUS.DRAFT_LIKED;
    case 'in_review': return CARD_PURCHASE_STATUS.IN_REVIEW;
    case 'rejected':  return CARD_PURCHASE_STATUS.REJECTED;
    case 'published': return CARD_PURCHASE_STATUS.PUBLISHED;
    case 'review_publish_reject': return CARD_PURCHASE_STATUS.REVIEW_PUBLISH_REJECT;
    default: return CARD_PURCHASE_STATUS.DEFAULT_3DWAY;
  }
}

export function processCardPrice(price: string | number | undefined): number | undefined {
  if (typeof price === 'string' && /^\d+$/.test(price)) {
    return parseInt(price, 10);
  } else if (typeof price === 'number') {
    return price;
  }
  return undefined;
}

export function convertCardsToDtoCards(cards: ICard[]): DtoICard[] {
  return cards.map(cardOriginal => {
    return {
      model_id: cardOriginal.id,
      last_update: cardOriginal.modifiedDate ? convertDateToISO8601(cardOriginal.modifiedDate) : new Date().toISOString(),
      public_model_id: cardOriginal.publicModelId,
      collectionId: cardOriginal.parentCollectionId,
      preview_link_app: cardOriginal.cardContentMobile,
      poster_link_app: cardOriginal.cardPosterMobile,
      poster_link_web: cardOriginal.cardPosterWeb,
      preview_link_web: cardOriginal.cardContentWeb,
      user_id: cardOriginal.cardAuthor,
      card_category: cardOriginal.cardCategory,
      card_subcategory: 'N/A',
      download_link: cardOriginal.cardModelLink,
      title: cardOriginal.cardTitle,
      description: cardOriginal.cardDescription,
      price: processCardPrice(cardOriginal.cardPrice),
      type: cardOriginal.cardType === CARD_TYPE.COLLECTION ? 'collection' : 'model',
      previewType: cardOriginal.cardType,
      status: cardOriginal.purchaseStatus,
      review_status: cardOriginal.purchaseStatus
    };
  });
}

// TEMPORARY WORKAROUND FOR SETTING 'UPLOADED' STATUS FOR AUTHORS BEFORE WE AGREE WITH YAHOR UP TO STATUSES
function setCustomStatus(status: string, user: string): CARD_PURCHASE_STATUS {
  let storedUid: string | null = null;
  try { storedUid = localStorage.getItem('originalUid') } catch (error) { }
  return (status === 'private' && storedUid === user)
    ? CARD_PURCHASE_STATUS.UPLOADED
    : CARD_PURCHASE_STATUS.DEFAULT_3DWAY;
}

function convertDateToISO8601(date: string): string {
  const dateObj = new Date(date);
  return dateObj.toISOString();
}
