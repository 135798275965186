import { UtilityFunctionsService } from './utility-functions.service';
import { ModelCardRendered, StripePrices } from '../../models/card';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subject, BehaviorSubject, lastValueFrom } from 'rxjs';
import { ICard, } from 'src/app/models/card';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FirebaseAuthService, PURCHASE_PROCESS } from './firebase-auth.service';
import { IappurchaseService } from './iappurchase.service';
import { RollbarService, CustomRollbar } from './rollbarlog.service';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  public onCardPurchaseStateChange: BehaviorSubject<PURCHASE_PROCESS> = new BehaviorSubject(PURCHASE_PROCESS.DEFAULT);
  public onSubscriptionPurchaseStateChange: Subject<PURCHASE_PROCESS> = new Subject();


  private currentPurchaseIds: string[];
  private currentModelsIds: string[];

  // eslint-disable-next-line max-len
  constructor(
    private http: HttpClient,
    public firebaseAuth: FirebaseAuthService,
    public utilityService: UtilityFunctionsService,
    public iappurchaseService: IappurchaseService,
    @Inject(RollbarService) private rollbar: CustomRollbar
  ) {

    this.iappurchaseService.nativeIndividualPurchaseSubscription.subscribe((process) => {
      this.triggerCardPurchaseStateChange(process);
    })

    this.iappurchaseService.nativeSubscriptionPurchaseSubscription.subscribe((process) => {
      switch (process) {
        case PURCHASE_PROCESS.DEFAULT:
          console.log('SUBSCRIPTION PURCHASE_PROCESS.DEFAULT')
          this.onSubscriptionPurchaseStateChange.next(PURCHASE_PROCESS.DEFAULT);
          break;
        case PURCHASE_PROCESS.ERROR:
          console.log('SUBSCRIPTION PURCHASE_PROCESS.ERROR')
          this.onSubscriptionPurchaseStateChange.next(PURCHASE_PROCESS.ERROR);
          break;
        case PURCHASE_PROCESS.PROCESSING:
          console.log('SUBSCRIPTION PURCHASE_PROCESS.PROCESSING');
          this.onSubscriptionPurchaseStateChange.next(PURCHASE_PROCESS.PROCESSING);
          break;
        case PURCHASE_PROCESS.SUCCESS:
          console.log('SUBSCRIPTION PURCHASE_PROCESS.SUCCESS');
          this.onSubscriptionPurchaseStateChange.next(PURCHASE_PROCESS.SUCCESS);
          break;
        default:
          break;
      }
    })
  }


  public async triggerCardPurchaseStateChange(newState: PURCHASE_PROCESS) {
    switch (newState) {
      case PURCHASE_PROCESS.DEFAULT:
        console.log('PURCHASE_PROCESS.DEFAULT')
        this.onCardPurchaseStateChange.next(PURCHASE_PROCESS.DEFAULT);
        break;
      case PURCHASE_PROCESS.ERROR:
        console.log('PURCHASE_PROCESS.ERROR')
        this.onCardPurchaseStateChange.next(PURCHASE_PROCESS.ERROR);
        break;
      case PURCHASE_PROCESS.VERIFICATION:
        console.log('PURCHASE_PROCESS.VERIFICATION');
        this.onCardPurchaseStateChange.next(PURCHASE_PROCESS.VERIFICATION);
        break;
      case PURCHASE_PROCESS.PROCESSING:
        console.log('PURCHASE_PROCESS.PROCESSING');
        this.onCardPurchaseStateChange.next(PURCHASE_PROCESS.PROCESSING);
        break;
      case PURCHASE_PROCESS.SUCCESS:
        console.log('PURCHASE_PROCESS.SUCCESS');
        console.log('PURCHASE_IDs: ', this.currentPurchaseIds);
        let resp;
        try {
          resp = await this.postPurchaseConfirmation(this.currentModelsIds);
        } catch (error) {
          console.log('ERROR postPurchaseConfirmation;')
          console.log(error)
          if (error.error) this.utilityService.showErrorSnackBar(JSON.stringify(error.error), 3000)
          else this.utilityService.showErrorSnackBar(JSON.stringify(error), 3000);
        }
        console.log('RESPONSE: ', resp);
        if (resp?.status === 'confirmed') {
          this.onCardPurchaseStateChange.next(PURCHASE_PROCESS.SUCCESS);
        } else {
          if (resp) this.utilityService.showErrorSnackBar(JSON.stringify(resp), 3000);
          // else this.utilityService.showErrorSnackBar('Unknown error, please, contact support', 3000);
          this.onCardPurchaseStateChange.next(PURCHASE_PROCESS.ERROR);
        }

        break;

      default:
        break;
    }
  }


  public async processConsumableInAppPurchaseRequest(cards: ModelCardRendered[]): Promise<void> {
    let purchaseDetails;
    console.log('processConsumableInAppPurchaseRequest REQUESTED')
    if (this.onCardPurchaseStateChange.getValue() === PURCHASE_PROCESS.PROCESSING) return;
    console.log('processConsumableInAppPurchaseRequest STARTED')
    this.triggerCardPurchaseStateChange(PURCHASE_PROCESS.PROCESSING);
    const uid = await this.firebaseAuth.getUserUid()

    try {
      this.rollbar.info(`cardPurchaseIntent for user ${uid}`)
      purchaseDetails = await this.postRequestPurchaseIntent(cards);
    } catch (err) {
      if (err.error.error) {
        this.utilityService.showErrorSnackBar(JSON.stringify(err.error.error), 2000);
        this.rollbar.error(`cardPurchaseIntent ERROR, user ${uid}`, err)
      } else {
        this.utilityService.showErrorSnackBar(JSON.stringify(err), 2000);
        this.rollbar.error(`cardPurchaseIntent ERROR, user ${uid}`, err)
      }
      this.triggerCardPurchaseStateChange(PURCHASE_PROCESS.ERROR);
      return
    }

    if (purchaseDetails?.model_available && purchaseDetails?.product_id) this.buyConsumable(purchaseDetails.purchase_id, cards, purchaseDetails.product_id);
    else {
      this.triggerCardPurchaseStateChange(PURCHASE_PROCESS.DEFAULT);
      this.utilityService.showErrorSnackBar('Purchase request failed: ' + purchaseDetails, 2000)
      this.rollbar.error(`Purchase request failed , user ${uid}`, purchaseDetails)
    }
  }


  public buyConsumable(purchaseIds: string[], cards: ModelCardRendered[], productId: string): void {
    this.currentPurchaseIds = purchaseIds;
    this.currentModelsIds = cards.map(card => card.id);

    try {
      this.iappurchaseService.activateConsumablePurchase(cards, productId).catch((error) => {
        this.utilityService.showErrorSnackBar(error.toString(), 2000);
        console.error(error);
        this.rollbar.error('Error in buy consumable ', error)
        this.triggerCardPurchaseStateChange(PURCHASE_PROCESS.ERROR)
      })
      // this.store.order('generic.3dmodel.purchase', {applicationUsername: this.firebaseAuth.currentUser.uid}).then((resp) => {
      //   console.log(resp);
      // }).error((err) => {
      //   console.error(err);
      //   this.triggerCardPurchaseStateChange(PURCHASE_PROCESS.ERROR)
      // });
    } catch (error) {
      this.utilityService.showErrorSnackBar(error.toString(), 2000);
      console.error(error);
      this.rollbar.error('Error in buy consumable ', error)
      this.triggerCardPurchaseStateChange(PURCHASE_PROCESS.ERROR)
    }

  }


  public async postRequestPurchaseIntent(cards: ICard[]): Promise<number> {
    const userToken = await this.firebaseAuth.getUserToken();
    const uid = await this.firebaseAuth.getUserUid();
    const cardsId = cards.map(card => card.id);
    const price = cards.reduce((acc, val) => {
      return acc = acc + val.cardPrice;
    }, 0)
    const postBody = { 'model_ids': cardsId, 'user_id': uid, price }
    let response
    try {
      response = await lastValueFrom(this.http.post(`${environment.endpoints.purchaseIos}/validate_purchase`, postBody, this.getRequestOptions(userToken)));
      console.log('response validate_purchas', response)
    } catch (error) {
      if (error?.error?.error) {
        console.log('postRequestPurchaseIntent: ', error.error.error);
        return error.error.error;
      } else return JSON.stringify(error.error) as any;
    }
    return response
  }

  public async postPurchaseConfirmation(cardIds: string[]): Promise<any> {
    const uid = await this.firebaseAuth.getUserUid();
    const userToken = await this.firebaseAuth.getUserToken();
    const postBody = { 'model_ids': [...cardIds], 'user_id': uid, 'app_store_receipt': this.iappurchaseService.lastPurchaseAppStoreReciept }

    let response
    try {
      response = await lastValueFrom(this.http.post(`${environment.endpoints.purchaseIos}/confirm_purchase`, postBody, this.getRequestOptions(userToken)));
      console.log('response confirm_purchase', response)
    } catch (error) {
      if (error?.error?.error) {
        console.log('purchaseConfError: ', error.error.error);
        return error.error.error;
      } else return JSON.stringify(error.error) as any;
    }
    return response
  }

  public async getListOfUserPurchasedModels(): Promise<any> {
    // getUserPurchasesModels
    const uid = await this.firebaseAuth.getUserUid();
    const userToken = await this.firebaseAuth.getUserToken();
    const postBody = { 'user_id': uid }
    let response: any = await lastValueFrom(this.http.post(`${environment.endpoints.purchaseIos}/purchased_models`, postBody, this.getRequestOptions(userToken)));
    if (response === null) response = [];
    console.log('getListOfUserPurchasedModels : ', response);
    return response
  }

  public async createPurchaseIntent(): Promise<any> {
    // getUserPurchasesModels
    const uid = await this.firebaseAuth.getUserUid();
    const userToken = await this.firebaseAuth.getUserToken();
    const postBody = {
      'priceId': 'price_1P3uWL2K1MDKKgy5GUWiPP9i',
      'quantity': 1200,
      'user_id': uid,
      'paymentMethodTypes': [
        'card'
      ]
    }
    let response: any = await lastValueFrom(this.http.post(`${environment.endpoints.purchaseWeb}/create-payment-intent`, postBody, this.getRequestOptions(userToken)));
    if (response === null) response = [];
    console.log('getListOfUserPurchasedModels ', response);
    return response
  }

  private getRequestOptions(token: string): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token,
      }),
      reportProgress: true,
      withCredentials: true
    };
    return httpOptions;
  }



}


