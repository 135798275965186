<app-navbar #navbarRef [footerBarMode]="currentPortfolioFooterMode" [additionalData]="portfolioAdditionalFooterData"  (navbarButtonClick)="onNavbarBtnClick($event)" (footermodeChange)="onFootermodeChanged($event)"></app-navbar>
<app-library-section-switch [ngClass]="getDarkModeClass()" #switchPanelRef [selectedSwitchBtn]="currentPortfolioSwitchPanelMode"  (switchBtnClicked)="onPortfolioSwitchPanelClick($event)"></app-library-section-switch>
<app-subscription-splash [@fullscreenPanelAnimation]  *ngIf="isSubscriptionSplashShown" [tryForFreeMode]="!userDataService.isFreeUploadLimitReached && !userDataService.isTryFreeEnabled" ></app-subscription-splash>

<div class="portfolio-page" [ngClass]="getDarkModeClass()">
  <div [@inOutAnimation] class="search-input-wrapper" fxLayout="row" fxLayoutAlign="space-between center" >
    <h1 class="library-title">Your Library</h1>
    <button  class="additional-navigation-button" (click)="onTopNavAdditionalMenuBtnClicked()">
      <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="9" cy="14" r="1.5" fill="#828282"/>
        <circle cx="14" cy="14" r="1.5" fill="#828282"/>
        <circle cx="19.1426" cy="14" r="1.5" fill="#828282"/>
        <circle cx="14" cy="14" r="10.125" stroke="#828282" stroke-width="1.75"/>
        </svg>
    </button>
  </div>


  <!-- <div class="cards-not-incollection-area" > -->
  <div class="items-wrapper">
    <ng-container *ngIf="currentPortfolioSwitchPanelMode === SWITCH_PANEL_ITEM.UPLOADS">

      <app-thumbnail-gallery [sourceCards]="userDataService.uploadedCards"  (cardClicked)="onUploadedCardClicked($event)"  class="zero-margin last-slider" >
        <div class="nodata-wrapper" *ngIf="uploadService.uploadModels?.length === 0 && userDataService.uploadedCards?.length === 0">
          <h1 class="nodata-title">Upload your first model</h1>
          <h3 class="nodata-desc">You don't have any uploaded models</h3>
        </div>
        <app-card-thumbs-upload  class="gallery-thumb" *ngFor="let upCard of uploadService.uploadModels | callback: filterUploadCards" [uploadCard]="upCard" fxFlex="0 1 calc(33.3334% - 6.33px)" fxLayout="column" fxLayoutAlign="start center"></app-card-thumbs-upload>
      </app-thumbnail-gallery>
    </ng-container>
    <ng-container *ngIf="currentPortfolioSwitchPanelMode === SWITCH_PANEL_ITEM.LIKES">
      <div class="nodata-wrapper" *ngIf="userDataService.getSavedCards()?.length === 0">
        <h1 class="nodata-title">No saved models</h1>
        <h3 class="nodata-desc">You don't have any saved models</h3>
      </div>

      <app-thumbnail-gallery *ngIf="userDataService.getSavedCards()?.length > 0" [sourceCards]="userDataService.getSavedCards()" (cardClicked)="onSliderLikedCardClicked($event)"  class="zero-margin last-slider" ></app-thumbnail-gallery>

    </ng-container>
    <ng-container *ngIf="currentPortfolioSwitchPanelMode === SWITCH_PANEL_ITEM.PURCHASES">
      <div class="nodata-wrapper" *ngIf="userDataService.userPurchasedCards?.length === 0">
        <h1 class="nodata-title">Buy your first model</h1>
        <h3 class="nodata-desc">You don't have any purchased models</h3>
      </div>
      <app-card-horizontal-slider  [sourceCards]="userDataService.userPurchasedCards" (cardClicked)="onPurchasedModelsCardClicked($event)" ></app-card-horizontal-slider>
      <app-thumbnail-gallery *ngIf="userDataService.userPurchasedCollections?.length > 0" [sourceCollections]="userDataService.userPurchasedCollections" [galleryMode]="GALLERY_MODE.AGGREGATED_STATIC" [galleryTitle]="'Collections'" (collectionClicked)="onPurchasedCollectionClicked($event)" class="last-slider"></app-thumbnail-gallery>
    </ng-container>
    <ng-container *ngIf="currentPortfolioSwitchPanelMode === SWITCH_PANEL_ITEM.ALL">
      <div class="nodata-wrapper" *ngIf="filteredAllModels.length === 0 && uploadService.uploadModels.length === 0">
        <h1 class="nodata-title">Your models right here</h1>
        <h3 class="nodata-desc">You don't have any models</h3>
      </div>
      <app-thumbnail-gallery [sourceCards]="filteredAllModels" (cardClicked)="onAllModelsCardClicked($event)"  class="zero-margin last-slider" >
        <app-card-thumbs-upload  class="gallery-thumb" *ngFor="let upCard of uploadService.uploadModels | callback: filterUploadCards" [uploadCard]="upCard" fxFlex="0 1 calc(33.3334% - 6.33px)" fxLayout="column" fxLayoutAlign="start center"></app-card-thumbs-upload>
      </app-thumbnail-gallery>
    </ng-container>

  </div>

</div>

<!-- <app-collection-select-panel [@fullscreenPanelAnimation] #cardSelectRef  *ngIf="isCollectionSelectShown" [title]="'Move to Collection.'" [sourceCollections]="userDataService.allUserDraftCollections" [userSelectedCards]="[]" (collectionSelected)="onCollectionSelected($event)"></app-collection-select-panel> -->
<app-card-publish-panel [@fullscreenPanelAnimation] #cardPublishRef  *ngIf="isCardPublishShown" [title]="'Select Models.'" [cardsToSelect]="cardSelectData" [userSelectedCards]="[]" (cardSelected)="onCardToPublishSeletected($event)" (showCardPriceSelect)="onShowCardPriceToPublishSelectEvent($event)"></app-card-publish-panel>
<app-price-select-panel [@fullscreenPanelAnimation] #priceSelectRef *ngIf="isPriceSelectShown" [selectedPriceOption]="cardToEdit.cardPrice" (priceSelected)="onPriceSelected($event)"></app-price-select-panel>
<app-name-change-dialogue [@fullscreenPanelAnimation] *ngIf="isNameChangeDialogueShown" [inputValue]="nameDialogDefaultValue" (valueChanged)="onNameDialogValueChanged($event)"></app-name-change-dialogue>

<app-card-select-panel [@fullscreenPanelAnimation] #cardSelectRef  *ngIf="isCardSelectShown" [title]="'Select Models.'" [showCardPrice]="false" [cardsToSelect]="cardSelectData" [userSelectedCards]="[]" (cardSelected)="onCardSelected($event)" (cardSelectSwitchPanelClick)="onCardSelectSwitchPanelClicked($event)"></app-card-select-panel>
<app-card-buy-panel [@fullscreenPanelAnimation]  *ngIf="isBuySelectShown" [title]="'Select Model to Purchase'" [disableClick]="currentPurchaseState === PURCHASE_PROCESS.PROCESSING || currentPurchaseState === PURCHASE_PROCESS.VERIFICATION" [ngClass]="currentPurchaseState" [cardsToSelect]="cardSelectData" (cardSelected)="onCardToPurchaseSelected($event)" ></app-card-buy-panel>
<app-navpanel *ngIf="isNavpanelShown" [@navpanelAnimation]="navpanelAnimationState"  #navPanelRef [navpanelActionItems]="portfolioNavpanelItems" (hideNavpanelEvent)="onHideNavpanelEventEmitted()" (navpanelActionClickEvent)="onNavpanelActionItemClicked($event)" ></app-navpanel>
<app-collection-details #collectionDetailsComponent *ngIf="isCollectionDetailsShown" [@collectionDetailsFadeAnimation]="isCollectionDetailsShown" [filterOptions]="filterCardMode" [sourceCollection]="collectionDetailsSource" [collectionEditOptions]="collectionEditOptions" (collectionDetailsThumbClick)="onCollectionDetailsThumbClick($event)" (controlButtonClick)="onCollectionDetailsControlBtnClick($event)"></app-collection-details>
<app-models-card @modelFeedAnimation #modelCardsFeed class="feed-overlap model-feed" *ngIf="isModelFeedShown" [startPosition]="cardFeedCardStartPosition" [feedIsVisible]="isModelFeedShown" [cardsRaw]="modelCardsMock" [feedMode]="cardFeedMode" (cardPressed)="onModelCardPressed()" (cardClicked)="onModelCardClicked($event)" (actionBtnClicked)="onCardControlBtnClicked($event)"></app-models-card>
<app-create-new-collection [@fullscreenPanelAnimation] *ngIf="isCreateNewCollectionShown"></app-create-new-collection>

